import React from "react";
import { useFaildReports, useFlashReports, useReports, useUpdateCorrection } from "../../projectManagementQueries";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import {
  Button, Checkbox, Grid, LinearProgress, Paper, TableContainer, TextField, Typography, IconButton,
  Radio,
  Icon,
  Tooltip,
} from "@material-ui/core";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { generalFilter, useDefaultColumn } from "../../../../util/filter";
import { useHistory } from "react-router";
import { TravelerModal } from "../../../common/travelerModal";
import { useFetchContext } from "../../../common/FetchContext";
import { urls } from "../../../common/urls";
import moment from "moment";
import { toast } from "react-toastify";
import FlashReportsTable from "./FlashReportsTable";
import { FlashReport } from "./FlashReport";
import { HeaderProps, Row, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Alert, AlertTitle } from "@material-ui/lab";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { renderHeader } from "../../../common/util";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as Yup from "yup";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link } from "@material-ui/core";
import { Edit, Label, PublishRounded, UpdateRounded } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { Link as MuiLink, useTheme } from "@material-ui/core/";
import { constant } from "lodash";
import { EditProjectItems } from "../myprojects/EditProjectItems";
import { Field, Form, Formik } from "formik";
import { processErrorOnMutation } from "../../../../util/errorMessaging";
import CustomDialog from "../../../common/CustomDialog";
import { formatDate } from "../../../common/formatDate";




const FlashReports = () => {

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [flashReports, setFlashReports] = React.useState([]);

  const [dataAvailable, setDataAvailable] = React.useState(false);


  const {
    mutateAsync: mutateGetFlashReports,
    error: errorMutateFlashReports,
    isError: isErrorMutateFlashReports,
    isSuccess: isSuccessFlashReports,
    isLoading: isLoadingFlashReports,
  } = useFlashReports();

  const [open, setOpen] = React.useState(false);
  const [openOldMesurement, setOpenOldMesurement] = React.useState(false);
  const [oldValues, setOldValues] = React.useState<any>([]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleCloseOldMesurement = () => {
    setOpenOldMesurement(!openOldMesurement);
  };




  React.useEffect(() => {
    setFlashReports(flashReports);
  }, [flashReports.length, flashReports]);


  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  

  const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
  const [imageAdjust, setImageAdjust] = React.useState(false);
  const [vmp, setVmp] = React.useState("");
  const [isc, setIsc] = React.useState("");
  const [pmp, setPmp] = React.useState("");
  const [voc, setVoc] = React.useState("");
  const [imp, setImp] = React.useState("");


  const handleStartDateChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setStartDate(e.target.value);
  };

  const handleChangeVmp = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setVmp(e.target.value);
  };
  const handleChangeImp = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setImp(e.target.value);
  };
  const handleChangeVoc = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setVoc(e.target.value);
  };

  const handleChangeIsc = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setIsc(e.target.value);
  };

  const handleChangePmp = (e: { target: { value: React.SetStateAction<string>; }; }) => {

    console.log(e)
    setPmp(e.target.value);
  };
  const handleEndDateChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEndDate(e.target.value);
  };
  const searchData = async () => {


    if (startDate != '' && endDate != '') {
      var data = await mutateGetFlashReports({
        authAxios,
        startDate,
        endDate,
      });

      setFlashReports(data)

    } else {
      toast.warning('Please select start and end date')

    }


  };



  const { authAxios } = useFetchContext();
  const { mutateAsync: mutate } = useUpdateCorrection();


  const getDownloads = async (): Promise<any> => {
    if (flashReports.length != 0) {
      const response = await authAxios.get(urls.actionResults.flash_report_download(startDate, endDate), {
        responseType: 'blob'
      });

      const downloadUrl = URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${moment().format("MMM-DD-YYYY-HHMMSS")}`;
      document.body.appendChild(a);
      a.click();

    } else {
      toast.warning('No data found to download')

    }

  };


  const getDownloadsCorrectionFactor = async (): Promise<any> => {
    if (flashReports.length != 0) {
      const response = await authAxios.get(urls.actionResults.flash_correction_factor_download, {
        responseType: 'blob'
      });

      const downloadUrl = URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${moment().format("MMM-DD-YYYY-HHMMSS")}`;
      document.body.appendChild(a);
      a.click();

    } else {
      toast.warning('No data found to download')

    }

  };

  const getCorrections = async (): Promise<any> => {
    const unitIds = selectedFlatRows
      .map((d) => d.original)
      .map((row: any) => row.id);

    if (unitIds.length) {
    
      setSelectedIds(unitIds)
      setOpen(true)
    } else {
      toast.warn('Please select serial number')
    }



  };

  const defaultColumn = useDefaultColumn();

  const columns = React.useMemo(
    () => [

      {
        Header: "ID",
        id:'id',
        accessor: "id",
        filter: generalFilter,
      },
      {
        id:"old_data",
        accessor:'old_correction_value'
      },
      {
        id:"Date Time",
        accessor:"date_time",
        Cell:({cell}:any)=>{
          return  <p>{formatDate(cell.value)}</p>

        }
      },
      {
        id: "Serial Number",
        accessor: "unit_serial_number",
        filter:generalFilter
        // Cell: ({ cell }: any) => {
        //   return <TravelerModal serialNumber={cell.value} />;
        // },
      },
      {
        id:"Project Number",
        accessor: "project_number",
        filter:generalFilter

      },
      {
        id:"Customer",
        accessor: "customer_name",
        filter:generalFilter
      },
      {
        id:"WorkOrder",
        accessor: "work_order_name",
        filter:generalFilter
      },
      {
        id: "Char Point",
        accessor: "name",
        filter: generalFilter
      },
      {
        Header:"Procedure",
        id: "Procedure",
        accessor: 'procedure_definition_name',
        filter: generalFilter
      },
      {
        Header:"Disposition",
        id: "disposition",
        accessor: 'disposition_name',
        filter: generalFilter
      },
      {
        Header:"Pmp",
        id: "pmp",
        accessor: "correction_factor.Pmp",
        filter: generalFilter
      },
      {
        Header:"Isc",
        id: "ISC",
        accessor: "correction_factor.Isc",
        filter: generalFilter,
      },
      {
        Header:"Voc",
        id: "VOC",
        accessor: "correction_factor.Voc",
        filter: generalFilter,
      },

      {
        Header:"Vmp",
        id: "VMP",
        accessor: "correction_factor.Vmp",
        filter: generalFilter,
      },
      {
        Header:"Imp",
        id: "IMP",
        accessor: "correction_factor.Imp",
        filter: generalFilter,
      },

    


      // {
      //   Header:'Corrected',
      //   Cell: ({ cell,row }: any) => {
         

      //     return row.values.old_data !== null ? (
      //       <Button
      //       color="primary"
      //       onClick={() => {
      //         setOldValues(row.values.old_data);
      //         setOpenOldMesurement(true);
      //       }}>Updated</Button>
      //     ) : (<></> );
      //   },
      //   filter: generalFilter,
      // },


    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
  
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      data: flashReports,

      columns,
      defaultColumn,
      initialState: {
        hiddenColumns: ["id", "url", "old_data"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header:
           ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: Row<any> }) => (
            
            <div>
              {row.values.disposition!="Incorrect value"?<Checkbox {...row.getToggleRowSelectedProps()} />:<></>}
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
 







  if (isLoadingFlashReports) {
    return <LinearProgress />;
  }

  if (isErrorMutateFlashReports) {
    return <ErrorMessage error={errorMutateFlashReports} />;
  }



  return (
    <>
      <Typography variant="h5">Flash Report</Typography>

      <div style={{ margin: 10, padding: 10 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between" // Ensure items are spread across the row
        >
          <Grid item xs={6} container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item >

              <TextField
                id="start-date"
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true, // Keeps the label visible when a value is selected
                }}
                variant="outlined" // Adds an outline to the input
              />
            </Grid>

            <Grid item >
              <TextField
                id="end-date"
                label="End Date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true, // Keeps the label visible when a value is selected
                }}
                variant="outlined" // Adds an outline to the input
              />
            </Grid>

            <Grid item>
              <Button
                variant="outlined" // Outlined button
                color="primary"
                onClick={searchData}
              >
                SEARCH
              </Button>
            </Grid>
            <Grid item>
            <Typography variant="h5">{flashReports.length?'Available :'+flashReports.length:""}</Typography>
            </Grid>
          </Grid>

          {flashReports.length ? (
            <Grid item xs={6} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getDownloadsCorrectionFactor}
                >
                  Download Corrected Procedures
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getDownloads}
                >
                  Download Flash Reports
                </Button>
              </Grid>
              
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getCorrections}
                >
                  Update Correction Factor
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

      </div>
      {/* {flashReports.length ? <FlashReport reports={flashReports} /> :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography variant="h5">No result found</Typography>
        </div>
      } */}
      {flashReports.length ?

        <TableContainer
          data-testid="tableContainer"
          // style={theme.tableContainer}
      
          component={Paper}
        >
         <MuiTable  stickyHeader {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell  style={{ whiteSpace: 'nowrap' }}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap" 
                        spacing={0}
                      >
                        <Grid item {...column.getHeaderProps()}>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={0}
                            wrap="nowrap"
                          >
                            <Typography variant="subtitle1" noWrap>
                              {renderHeader(column)}
                            </Typography>
                            {column.canFilter ? column.render("Filter") : null}
                            <Grid item wrap="nowrap">
                              {/* <IconButton
                                type="submit"
                                aria-label="search"
                                size="small"
                                {...column.getSortByToggleProps()}
                              >
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )
                                ) : (
                                  column.id !== "selection" && <CallSplitIcon />
                                )}
                              </IconButton> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}  style={{ whiteSpace: 'nowrap' }}>
                          {cell.render("Cell")}
                          {/* {cell.column.id === "select" ? (
                            <Checkbox
                              checked={selectedRowId === row.values.id} // Check if this row is selected
                              onChange={() => handleRowSelect(row.values.id)} // Update selected row on change
                            />
                          ) : (
                            cell.render("Cell")
                          )} */}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography variant="h5">No result found</Typography>
        </div>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={false}>
        <DialogTitle id="id">
          <div>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} >{"Update Correction Factor"}</Box>
              <Box>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <LastEditedProject id={id} /> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider variant="fullWidth" />
          <Formik
            initialValues={{


            }}
            // validationSchema={Yup.object({
            //   imp: Yup.string().required("Field must be required"),
            //   voc: Yup.string().required("Field must be required"),
            //   isc: Yup.string().required("Field must be required"),
            //   vmp: Yup.string().required("Field must be required"),
            //   pmp: Yup.string().required("Field must be required"),

            // })}
            onSubmit={async (values, { setSubmitting }) => {

              try {
                console.log("Form values", JSON.stringify(values));
                var date = ""

                if(imp!="" && vmp != "" && voc != "" && isc != "" && pmp != ""){
                  const newProjectData = await mutate({
                    

                    authAxios,
                    procedureresult_id: selectedIds,
                    update_data: {
                      "Imp": imp,
                      "Voc": voc,
                      "Isc": isc,
                      "Vmp": vmp,
                      "Pmp": pmp
                    }
  
                  });
                  console.log("Project data", newProjectData);
  
                  setSubmitting(false);
                  // setSelectedIds("")
                  await searchData(); // Fetch updated flash reports
  
                  handleClose();
                  toast.success("Project was succesfully changed");
                }else{
                  toast.warning("Field must be required");

                }


              } catch (error) {
                toast.error("Error while modifying project.");
                processErrorOnMutation(error, history);
              }
            }}
          >
            {({ errors, touched, submitForm, resetForm }) => (
              <Form>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item sm={12}>



                    <Field
                      id="number"
                      type="number"
                      name="Imp"
                      fullWidth
                      component={TextField}
                      // helperText={
                      //   touched.imp ? errors.imp : ""
                      // }
                      // error={
                      //   touched.imp &&
                      //   Boolean(errors.imp)
                      // }
                      style={{ marginBottom: 32 }}
                      label="imp"
                      data-testid="Imp"
                      onChange={handleChangeImp}
                    ></Field>
                    <Field
                      id="number"
                      type="number"
                      name="Voc"
                      fullWidth
                      component={TextField}
                      // helperText={
                      //   touched.voc ? errors.voc : ""
                      // }
                      // error={
                      //   touched.voc &&
                      //   Boolean(errors.voc)
                      // }
                      style={{ marginBottom: 32 }}
                      label="voc"
                      data-testid="voc"
                      onChange={handleChangeVoc}
                    ></Field>
                    <Field
                      id="number"
                      type="number"
                      name="Isc"
                      fullWidth
                      component={TextField}
                      // helperText={
                      //   touched.isc ? errors.isc : ""
                      // }
                      // error={
                      //   touched.isc &&
                      //   Boolean(errors.isc)
                      // }
                      style={{ marginBottom: 32 }}
                      label="isc"
                      data-testid="isc"
                      onChange={handleChangeIsc}
                    ></Field>
                    <Field
                      id="number"
                      type="number"
                      name="Vmp"
                      fullWidth
                      component={TextField}
                      // helperText={
                      //   touched.vmp ? errors.vmp : ""
                      // }
                      // error={
                      //   touched.vmp &&
                      //   Boolean(errors.vmp)
                      // }
                      style={{ marginBottom: 32 }}
                      label="vmp"
                      data-testid="vmp"
                      onChange={handleChangeVmp}
                    ></Field>
                    <Field
                      id="number"
                      type="number"
                      name="Pmp"
                      fullWidth
                      component={TextField}
                      // helperText={
                      //   touched.pmp ? errors.pmp : ""
                      // }
                      // error={
                      //   touched.pmp &&
                      //   Boolean(errors.pmp)
                      // }
                      style={{ marginBottom: 32 }}
                      label="pmp"
                      data-testid="pmp"
                      onChange={handleChangePmp}
                    ></Field>
                  </Grid>

                </Grid>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  style={{ marginTop: 32 }}
                >
                  <Grid item xs={2}>
                    <Button
                      data-testid="submitCrateId"
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openOldMesurement}
        onClose={handleCloseOldMesurement}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={false}>
        <DialogTitle id="id">
          <div>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} >{"Previous Measurement Values"}</Box>
              <Box>
                <IconButton onClick={handleCloseOldMesurement}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <LastEditedProject id={id} /> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider variant="fullWidth" />

          <div className="dialog-body">
                    {oldValues && oldValues.length > 0 ? (
                        oldValues.map((value: { procedure_result_id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; step_result_id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; pmp: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; voc: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; vmp: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; isc: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; imp: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                               
                                <p><strong>Pmp:</strong> {value.pmp}</p>
                                <p><strong>Voc:</strong> {value.voc}</p>
                                <p><strong>Vmp:</strong> {value.vmp}</p>
                                <p><strong>Isc:</strong> {value.isc}</p>
                                <p><strong>Imp:</strong> {value.imp}</p>
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                </div>

         
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default FlashReports;
