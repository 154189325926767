import { useIsFetching, useQueryClient } from "react-query";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, Radio, RadioGroup, Select, TextField, useTheme } from "@material-ui/core";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { useLocations, useModuleInventory } from "../../../intake/intakeQueries";
import { ModuleInventoryTablePage } from "./ModuleInventoryTablePage";
import React, { ChangeEvent } from "react";
import Pagination from "@material-ui/lab/Pagination"; // Make sure to import Pagination
import { useDispositions, useProjectDispositions, useWorkOrderDispositions, useWorkOrderUnitsDispositions } from "../../../common/services/dispositionServices";
import { useHistory } from "react-router-dom";

export const ModuleInventoryPage = () => {
  const [page, setPage] = React.useState(1); // State for current page
  const itemsPerPage = 100; // Number of items per page
  const isFetching = useIsFetching();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [status, setStatus] = React.useState('all');
  const [serialNumber, setSerialNumber] = React.useState("");
  const [project_number, setProjectNumber] = React.useState('');
  const [customer, setCustomer] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [action, setAction] = React.useState(1);

  const {
    data: data,
    error: errorData,
    isLoading: isLoading,
    isError: isErrorData,
  } = useModuleInventory(status=='all'?false:true,serialNumber,project_number,customer,location,page == 1 ? 1 : (page - 1) * 100, itemsPerPage,action);



  const {
    error: errorLocations,
    data: locations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
  } = useLocations();


  const {
    error: errorDispositions,
    data: dispositions,
    isLoading: isLoadingDispositions,
    isError: isErrorDispositions,
  } = useWorkOrderUnitsDispositions();

  const handleInputChangeSerial = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value);
  };
  const handleInputChangeProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectNumber(event.target.value);
  };

  const handleInputChangeCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer(event.target.value);
  };

  const handleInputChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };


  const handleButtonClick = () => {
    if(serialNumber!=""){
      setPage(0)
    }else{
      setPage(1)
    }
    setAction(action+1)
    queryClient.invalidateQueries(['module_inventory_data']);
  };

  const handleButtonClickClear = () => {
    setPage(1)
    setSerialNumber("")
    setProjectNumber("")
    setCustomer("")
    setLocation("")
    setStatus("all")
    setAction(action+1)
    queryClient.invalidateQueries(['module_inventory_data']);
  };
   



  if (isLoading || isLoadingLocations || isLoadingDispositions) {
    return (
      <Backdrop open={isLoading || isLoadingLocations || isLoadingDispositions}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isErrorData) return <>{isErrorData && <ErrorMessage error={isErrorData} />}</>;

  return (
    <>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
      
      
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <FormControl component="fieldset">
            <FormLabel component="legend">Filter by status</FormLabel>
            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={status}
              onChange={(event, value) => {
                console.log('Changing page to:', value);
                setStatus(value)
                setSerialNumber("")
                setProjectNumber("")
                setCustomer("")
                setLocation("")
                setPage(1);
                setAction(action+1)
                queryClient.invalidateQueries(['module_inventory_data']);
              }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All"  />
              <FormControlLabel value="active" control={<Radio />} label="Active" />
            </RadioGroup>
          </FormControl>
        <FormControl   style={{ marginLeft: '16px'}}  >
        <TextField
              label="Serial number"
              variant="outlined"
              value={serialNumber}
              onChange={handleInputChangeSerial}
            />
          </FormControl>
          <FormControl   style={{ marginLeft: '16px'}}  >
            <TextField
              label="Project Number"
              variant="outlined"
              value={project_number}
              onChange={handleInputChangeProject}
            />
          </FormControl>
          <FormControl   style={{ marginLeft: '16px'}}  >
            <TextField
              label="Customer"
              variant="outlined"
              value={customer}
              onChange={handleInputChangeCustomer}
            />
          </FormControl>
          <FormControl   style={{ marginLeft: '16px'}}  >
            <TextField
              label="Location"
              variant="outlined"
              value={location}
              onChange={handleInputChangeLocation}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '16px', marginTop: '10px' }}
            onClick={handleButtonClick}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '16px', marginTop: '10px' }}
            onClick={handleButtonClickClear}
          >
            Clear
          </Button>
        </Grid>

     
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(data.count / itemsPerPage)}
            page={page}
            onChange={(event, value) => {
              console.log('Changing page to:', value);
              setPage(value);
              queryClient.invalidateQueries(['module_inventory_data']);
            }}
            variant="outlined"
            color="primary"
          />
        </Grid>
  
      </Grid>

      <Box padding={1}></Box>
      {data && locations && (
        <>
          {<ModuleInventoryTablePage data={data.results} locations={locations} dispositions={dispositions} />}
          <Box padding={2}> </Box>
          <Pagination
            count={Math.ceil(data.count / itemsPerPage)}
            page={page}
            onChange={(event, value) => {
              console.log('Changing page to:', value);
              setPage(value);
              queryClient.invalidateQueries(['module_inventory_data']);
            }}
            variant="outlined"
            color="primary"
          />
        </>
      )}
    </>
  );
};
