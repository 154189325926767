import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import { useTestSequenceDefinitions, useTestSequenceDefinitionsAvailable } from "../../testSequenceDefinitionQueries";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  dispositionFilter,
  DispositionFilter,
  generalFilter,
} from "../../../../util/filter";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { SpaceBar } from "@material-ui/icons";
import TSDTemplate from "./TSDTemplate";
import { Autocomplete } from "@material-ui/lab";
import { Field, FieldProps, Form, Formik } from "formik";

interface TSD {
  id: number;
  short_name: string;
}

const TSDExisting = () => {
  const history = useHistory();





  const [tsdValue, setTsd] = React.useState("")
  const [isSelected, setSelected] = React.useState(false);
  const [tsd_value, setTsdValue] = React.useState({});

  const {
    data: data,
    error: tsdError,
    isLoading: isLoadingTSD,
    isError: isErrorTSD,
  } = useTestSequenceDefinitionsAvailable();

  if (isLoadingTSD) {
    return <LinearProgress />;
  }
  function onTsdSelected(data: any): void {
    if(data){
      setSelected(data ? true : false)
      setTsdValue(data)
      setTsd(data.id)
    }
  }


  const AutoCompleteField = ({ field, form, ...props }: FieldProps) => (
    <Autocomplete
      {...props}
      options={data && data}
      getOptionLabel={(option) => (option as TSD).short_name}
      onChange={(event, value) => onTsdSelected(value)}
      renderInput={(params) => <TextField {...params} label="TSD" fullWidth />}
    />
  );

  const AutoCompleteFieldSelected = ({ field, form, ...props }: FieldProps) => (
    <Autocomplete
      {...props}
      options={data && data}
      value={tsd_value}
      getOptionLabel={(option) => (option as TSD).short_name}
      onChange={(event, value) => onTsdSelected(value)}
      renderInput={(params) => <TextField {...params} label="TSD" fullWidth />}
    />
  );

  return (
    <div>
      <Box padding={2}>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >



          <Grid item xs={4}>
            <Typography variant="h5">Select Test Sequence Definitions</Typography>
          </Grid>

          <Grid item xs={6}>
            <Formik
              initialValues={{ tsd: '' }}
              onSubmit={(values) => {
                // handle form submission
              }}
            >
              {() => (
                <Form>
                  <Field
                    id="tsd"
                    type="text"
                    name="tsd"
                    fullWidth
                    component={isSelected ? AutoCompleteFieldSelected : AutoCompleteField}
                    style={{ marginBottom: 32 }}
                    label="TSD"
                    data-testid="tsd"
                  >
                    {/* {projects?.map((data: any) => (
                          <MenuItem
                            key={data.project.id}
                            value={data.project.project_number}
                            onClick={() => onProjectSelected(data)}>
                            {data.project.project_number}
                          </MenuItem>
                        ))} */}
                  </Field>
                </Form>

              )}
            </Formik>

          </Grid>

        </Grid>
      </Box>
      {tsdValue && <TSDTemplate tsdId={tsdValue}></TSDTemplate>}
    </div>
  );

};

export default TSDExisting;
