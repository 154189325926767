import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { EditProjectItems } from "../projectManagement/components/myprojects/EditProjectItems";
import CloseIcon from '@material-ui/icons/Close';

import {Link as MuiLink, useTheme } from "@material-ui/core/";
import { constant } from "lodash";
import { LastEditedProject } from "./LastEditedProject";


export const EditProjects = ({id, customer_name, sfdc_number, project_id}: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  
  return (
    <>
      <Button
        color="primary"
        onClick={handleClose}
        startIcon={<Edit />}/>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={false}>
        <DialogTitle id="id">
          <div>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{"Update Project Info"}</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <LastEditedProject id={id} />
          </div>
        </DialogTitle>
        <DialogContent>
        <Divider variant="fullWidth" />
        <EditProjectItems id={id} project_id={project_id} sfdc_number={sfdc_number} customer_name={customer_name}  handleClose={handleClose}/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  )
};
