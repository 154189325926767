import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  MenuItem,
  Button,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  useTheme,
  Link,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  editProjectDetails,
  useCustomers,
  useGroups,
  useProjectDetails,
  useProjectManagers,
} from "../../projectManagementQueries";
import { useDispositions } from "../../../common/services/dispositionServices";
import { useCustomerDetailsContext } from "../../../common/CustomerDetailsContext";
import { useAuthContext } from "../../../common/AuthContext";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { ViewNote } from "../../../common/ViewNoteDialog";
import { processErrorOnMutation } from "../../../../util/errorMessaging";

import { useProjectNotes } from "../../projectQueries";
import { ViewAddNoteList } from "../../../common/ViewAddNotes";
import { stringSanitize } from "../../../common/util";
import { Messaging } from "../../../common/enums";
import { editUpdateProject, lastUpdateProject } from "../../projectManagementMutations";
import { DatePicker, DateTimePicker } from "formik-material-ui-pickers";
import { LastEditedProject } from "../../../common/LastEditedProject";
import moment from "moment";

export const EditProjectItems =({id, customer_name, sfdc_number, project_id, handleClose}: any) => {
  const { dispatch } = useAuthContext();
  const history = useHistory();
  const theme = useTheme();

  const { mutateAsync: mutate } = editUpdateProject();
  const { mutateAsync: mutateLast } = lastUpdateProject();

  const {
    error: errorProjectDetails,
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    isError: isErrorProjectDetails,
    isSuccess: isSuccessProjectDetails,
  } = editProjectDetails(id);

  const {
    error: errorDispositions,
    data: dispositions,
    isLoading: isLoadingDispositions,
    isError: isErrorDispositions,
  } = useDispositions();

  const {
    data: groups,
    error: errorGroups,
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
    isSuccess: isSuccessGroups,
  } = useGroups();


  const {
    error: errorProjectManagers,
    data: projectManagers,
    isError: isErrorProjectManagers,
  } = useProjectManagers();





 

  if (isErrorProjectDetails || isErrorDispositions || isErrorProjectManagers) {
    return (
      <>
        {isErrorProjectDetails && <ErrorMessage error={errorProjectDetails} />}
        {isErrorDispositions && <ErrorMessage error={errorDispositions} />}
        {isErrorProjectManagers && (
          <ErrorMessage error={errorProjectManagers} />
        )}
      </>
    );
  }

  if (
    isLoadingProjectDetails ||
    isLoadingDispositions ||
    isLoadingDispositions||isLoadingGroups
  ) {
    return (
      <Backdrop
        open={
          isLoadingProjectDetails ||
          isLoadingDispositions ||
          isLoadingDispositions||isLoadingGroups
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div style={theme.container}>
      <Container>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
           <Grid item xs={12}>
              <div>
              <Typography variant="subtitle1" color="secondary">
                Project Id       : {project_id}
              </Typography>
              </div>
             
              <div>
              <Typography variant="subtitle1" color="secondary">
                SFDC Number          : {sfdc_number}
              </Typography>
              </div>
              <div>
              <Typography variant="subtitle1" color="secondary">
                Customer : {customer_name}
              </Typography>

              <div>
            </div>
              </div>
            </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                project_manager: projectDetails?.project_manager,
                disposition: projectDetails?.disposition,
                group:projectDetails?.group,
                customers:projectDetails?.customer,
                proposal_price: projectDetails?.proposal_price,
                start_date:projectDetails?.start_date,
                comments:""
              }}
              validationSchema={Yup.object({
                project_manager: Yup.string().required( "Field must be required"),
                disposition: Yup.string().required("Field must be required"),
                comments:Yup.string().required("Field must be required")
                // proposal_price: Yup.string().required("Field must be required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  
                  console.log("Form values", JSON.stringify(values));
                    var date = ""
                  if(values.start_date==null){
                    date = ""
                  }else{
                    date =moment(values.start_date).format("YYYY-MM-DD")
                  }
                  const newProjectData = await mutate({
                    number: "",
                    sfdc_number: "",
                    project_manager: values.project_manager,
                    disposition: values.disposition,
                    projectId: id,
                    group: values.group,
                    customer: "",
                    proposal_price: values.proposal_price,
                    start_date : date,
                    comments:values.comments,
                    location:""
                  });
                  console.log("Project data", newProjectData);

                  setSubmitting(false);

                  handleClose();                  
                  toast.success("Project was succesfully changed");
                  // window.location.reload();
                  
                } catch (error) {
                  toast.error("Error while modifying project.");
                  processErrorOnMutation(error, dispatch, history);
                }
              }}
            >
              {({ errors, touched, submitForm, resetForm }) => (
                <Form>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item sm={12}>
                    <Field
                    id="start_date"
                    name="start_date"
                    component={DatePicker}
                    format="YYYY-MM-DD"
                    style={{ marginBottom: 32 }}
                    label="Project Start Date"
                    inputVariant="outlined"
                    data-testid="start_date"
                    fullWidth
                    helperText={
                      touched.start_date ? errors.start_date : ""
                    }
                    error={
                      touched.start_date && Boolean(errors.start_date)
                    }
                  />
                      <Field
                        id="project_manager"
                        type="text"
                        name="project_manager"
                        select={true}
                        fullWidth
                        component={TextField}
                        helperText={
                          touched.project_manager ? errors.project_manager : ""
                        }
                        error={
                          touched.project_manager &&
                          Boolean(errors.project_manager)
                        }
                        style={{ marginBottom: 32 }}
                        label="Project Manager"
                        data-testid="project_manager"
                      >
                        {projectManagers?.map((projectManager: any) => (
                          <MenuItem
                            key={projectManager.id}
                            value={projectManager.url}
                          >
                            {projectManager.first_name}{" "}
                            {projectManager.last_name}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        id="disposition"
                        type="text"
                        name="disposition"
                        select={true}
                        fullWidth
                        component={TextField}
                        helperText={
                          touched.disposition ? errors.disposition : ""
                        }
                        error={
                          touched.disposition && Boolean(errors.disposition)
                        }
                        style={{ marginBottom: 32 }}
                        label="Disposition"
                        data-testid="disposition"
                      >
                        {dispositions?.map((disposition: any) => (
                          <MenuItem
                            key={disposition.id}
                            value={disposition.url}
                          >
                            {disposition.name}
                          </MenuItem>
                        ))}
                      </Field>        
                      <Field
                        id="group"
                        type="text"
                        name="group"
                        select={true}
                        fullWidth
                        component={TextField}
                        helperText={
                          touched.disposition ? errors.disposition : ""
                        }
                        error={
                          touched.disposition && Boolean(errors.disposition)
                        }
                        style={{ marginBottom: 32 }}
                        label="Group"
                        data-testid="group"
                      >
                        {groups?.map((group: any) => (
                          <MenuItem
                            key={group.id}
                            value={group.url}
                          >
                            {group.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        id="number"
                        type="number"
                        name="proposal_price"
                        fullWidth
                        component={TextField}
                        helperText={
                          touched.proposal_price ? errors.proposal_price : ""
                        }
                        error={
                          touched.proposal_price &&
                          Boolean(errors.proposal_price)
                        }
                        style={{ marginBottom: 32 }}
                        label="Proposal Price"
                        data-testid="proposal_price"
                      ></Field>



                        <Field
                        id="comments"
                        type="text"
                        name="comments"
                        fullWidth
                        component={TextField}
                        helperText={
                          touched.proposal_price ? errors.proposal_price : ""
                        }
                        error={
                          touched.proposal_price &&
                          Boolean(errors.proposal_price)
                        }
                        style={{ marginBottom: 32 }}
                        label="Comments"
                        data-testid="comments"
                      ></Field>
                    </Grid>
                   
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    style={{ marginTop: 32 }}
                  >
                    <Grid item xs={2}>
                      <Button
                        data-testid="submitCrateId"
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
