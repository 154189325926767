import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, colors, styled } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom";
import { useInventoryUpdate, useLocations, useModuleInfo, useModuleInventoryUnit } from "../../../intake/intakeQueries";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { Key } from "react";
import { useCrateInfo } from "../../../intake/intakeMutations";
import { Edit, NoteRounded } from "@material-ui/icons";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { formatDate } from "../../../common/formatDate";
import React from "react";

import { TextField, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from "formik";
import { DatePicker } from "formik-material-ui-pickers";
import { useDispositions } from "../../../common/services/dispositionServices";
import { toast } from "react-toastify";
import { useFetchContext } from "../../../common/FetchContext";
import { processErrorOnMutation } from "../../../../util/errorMessaging";
import { useQueryClient } from "react-query";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "primary",
  padding: theme.spacing(2),
  fontSize: 14,
  borderRadius: 10,
  color: "secodary",
}));





var moduleInfo;

export const ModuleInventoryInfo = () => {
  const history = useHistory();
  const { authAxios } = useFetchContext();

  const { serial_number } = useParams() as {
    serial_number: string;
  };

  const { mutateAsync: mutate } = useInventoryUpdate();


  const [open, setOpen] = React.useState(false)

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const [eolDisposition, setEolDisposition] = React.useState<string>('');

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleEolDispositionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEolDisposition(event.target.value as string);
  };

  const eolDispositionOptions = ['Option 1', 'Option 2', 'Option 3']; // Example options

  const [dispositionID, setDispositionId]=React.useState(null)
  const [locationId, setLoaction]=React.useState(null)

  const queryClient = useQueryClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onSelected(data: any): void {
    setDispositionId(data)
  }

  function SelectedLocation(data:any):void{
    setLoaction(data)
  }

  const saveData = () => {
    setOpen(false);
  };
  const {
    error: errorDispositions,
    data: dispositions,
    isLoading: isLoadingDispositions,
    isError: isErrorDispositions,
  } = useDispositions();
  const {
    error: errorData,
    data: data,
    isLoading: isloding,
    isError: isErrorData,
  } = useModuleInventoryUnit(serial_number);

  const {
    error: errorLocations,
    data: locations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
  } = useLocations();


  if (isErrorData) {
    return (<ErrorMessage error={errorData} />);
  }
  if (isloding) {
    return (
      <Backdrop open={isloding}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    // alert(JSON.stringify(moduleData.module_intake_details))
  }




  function dateChanged(date: Date | null): void {
    setSelectedDate(date)
  }

  return (
    <Box sx={{ flexGrow: 1 }} padding={2}>
      <Grid container spacing={2} alignItems="flex-start" >
        <Grid item xs={12}>
          <Box padding={2}>
            <Typography variant="h4" color="primary">Module Info
              <IconButton onClick={handleClickOpen}><Edit color="primary" /></IconButton>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} alignItems="flex-start" direction="column">
          <Item >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label>Serial Number: {serial_number}</label>

              </Grid>
              <Grid item xs={6}>
                <label>Project Number: {data.project_number}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Intake Number: {data.module_intake.id&&data.module_intake.id}</label>
              </Grid>
              <Grid item xs={6}>
                <label>WorkOrder Name: {data.workorder_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Customer: {data.module_intake.customer_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Manufaturere: {data.module_intake.customer_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Intake Location: {data.module_intake.intake_location_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Location: {data.location_name}</label>
              </Grid>

              <Grid item xs={6}>
                <label>Test Sequence: {data.test_sequence}</label>
              </Grid>
              <Grid item xs={6}>
                <label>EOL Dispossition: {data.eol_disposition}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Arivale Date: {data.arrival_date ? formatDate(data.arrival_date) : "NA"}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Intake Date: {data.module_intake.intake_date ? formatDate(data.module_intake.intake_date) : "NA"}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Project Close Out Date: {data.project_closeout_date ? formatDate(data.project_closeout_date) : "NA"}</label>
              </Grid>
              <Grid item xs={6}>
                <label>NTP Date: {data.ntp_date ? formatDate(data.ntp_date) : "NA"}</label>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={6} alignItems="flex-start" direction="column">
        <Item >
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <label>Module Type: {data.module_type}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Cell Type: {data.module_specification.module_technology_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Module Dimensions (mm): {data.module_specification.module_width}X{data.module_specification.module_height}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Mpp:  {data.module_specification.nameplate_pmax}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Voc: {data.module_specification.voc}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Isc: {data.module_specification.isc}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Vmp: {data.module_specification.vmp}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Imp: {data.module_specification.imp}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Rated Voltage: {data.module_specification.system_voltage}</label>
              </Grid>
              <Grid item xs={6}>
                <Box padding={6}>

                  </Box>
              </Grid>
              
              
            </Grid>
          </Item>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Module Info</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              arrival_date: data?.arrival_date,
              project_closeout_date:data?.project_closeout_date,
              disposition:data?.eol_disposition,
              location:data?.location

            }}

            onSubmit={async (values, { setSubmitting }) => {

              try {
                  
                await mutate({
                  authAxios,
                  serial_number : serial_number,
                  project_closeout_date:values.project_closeout_date,
                  arrival_date:values.arrival_date,
                  eol_disposition:dispositionID,
                  location:locationId
                });

                setSubmitting(false);

                handleClose();                  
                toast.success("Project was succesfully changed");
                // window.location.reload();
                queryClient.invalidateQueries("data");

              } catch (error) {
                toast.error("error data");
              }


           


            }}
          >
            {({ errors, touched, submitForm, resetForm }) => (
              <Form>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item sm={12}>
                    <Field
                      id="arrival_date"
                      name="arrival_date"
                      component={DatePicker}
                      format="YYYY-MM-DD HH:MM"
                      style={{ marginBottom: 32 }}
                      label="Arrival Date"
                      inputVariant="outlined"
                      data-testid="arrival_date"
                      fullWidth
                    />
                    <Field
                      id="project_closeout_date"
                      name="project_closeout_date"
                      component={DatePicker}
                      format="YYYY-MM-DD HH:MM"
                      style={{ marginBottom: 32 }}
                      label="Project Close Out Date"
                      inputVariant="outlined"
                      data-testid="project_closeout_date"
                      fullWidth
                    />
                    <Field
                      id="disposition"
                      name="disposition"
                      select={true}
                      fullWidth
                      component={TextField}
                      style={{ marginBottom: 32 }}
                      label="Disposition"
                      data-testid="disposition"
                    >
                      {dispositions?.map((disposition: any) => (
                        <MenuItem
                          key={disposition.id}
                          value={disposition.id}
                          onClick={() => onSelected(disposition.id)}>
                        
                          {disposition.name}
                        </MenuItem>
                      ))}
                    </Field>

                    <Field
                      id="location"
                      name="location"
                      select={true}
                      fullWidth
                      component={TextField}
                      style={{ marginBottom: 32 }}
                      label="Location"
                      data-testid="location"
                    >
                      {locations?.map((item: any) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          onClick={() => SelectedLocation(item.id)}>
                        
                          {item.name}
                        </MenuItem>
                      ))}
                    </Field>

                  </Grid>

                </Grid>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  style={{ marginTop: 32 }}
                >
                  <Grid item xs={2}>
                    <Button
                      data-testid="submitCrateId"
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}