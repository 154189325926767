import React from "react";
import { Typography, Grid, useTheme, IconButton, makeStyles, colors } from "@material-ui/core";
import { useTable, Column, useFilters, useSortBy } from "react-table";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { useDefaultColumn } from "../../util/filter";
import { renderHeader } from "./util";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import includes from "lodash/includes";


const defaultPropGetter = () => ({});

export const LSDBCoreTableWithoutDropDown = ({
  columns,
  data = [],
  hiddenColumns = [],
  getCellProps = defaultPropGetter,
}: {
  columns: Column<object>[];
  data: any;
  hiddenColumns?: string[];
  getCellProps?: any;
}) => {
  const tableColumns = React.useMemo(() => columns, [columns]);
  const defaultColumn = {}; 
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        data,
        columns: tableColumns,
        defaultColumn,
        initialState: {
          hiddenColumns: hiddenColumns,
        },
      },
      useFilters,
      useSortBy
    );

  return (
    <TableContainer data-testid="tableContainer" component={Paper}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  key={column.id}
                  style={{
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                    width: '100%',
                    backgroundColor: "green", 
                    color: "white",
                  }}
                >
                  <Typography variant="subtitle1">
                    {column.render("Header")}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell: any) => (
                  <TableCell
                    key={cell.column.id}
                    style={{
                      alignItems:'center',
                      whiteSpace: 'nowrap', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                    }}>
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
    </TableContainer>
  );
};
