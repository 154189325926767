import React, { useEffect, useRef } from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Backdrop, Box, Button, CircularProgress, Grid, Icon, IconButton, Input, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Edit, Scanner } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { FormFocusError } from "../../../common/FormFocusError";
import { STRESSOR_CHECK_PAUSE_MODE, STRESSOR_CHECK_RESUME_MODE, STRESSOR_CHECK_IN_MODE } from "../stressEntry/constants";
import { TextField as FormikTextField, TextField } from "formik-material-ui";
import { useFetchContext } from "../../../common/FetchContext";
import { urls } from "../../../common/urls";
import { toast } from "react-toastify";
import { useModuleIntakeInfo, useModulePannelBulkInsert, useModulePannelInsert } from "../../../intake/intakeQueries";
import { json } from "stream/consumers";
import { ScannedTable } from "../crateIntake/ScannedTable";






export const ModuleIntakePanel = () => {
  const history = useHistory();
  const { authAxios } = useFetchContext();
  const submitRef = React.useRef<any>();

  const { id } = useParams() as {
    id: string;
  };

  const { mutateAsync: mutatePostPannel } = useModulePannelInsert();
  const { mutateAsync: mutatePostBulkPannel } = useModulePannelBulkInsert();
 

  const {
    error: error,
    data: info,
    isLoading: isloading,
    isError: iserror,
  } = useModuleIntakeInfo(id);

  
  const [scannedData, setScannedData] = React.useState<any[]>([]);
  const [finalData, setfinalData] = React.useState<any[]>([]);

  const [test_type, setTestType] = React.useState<any[]>([]);
  // const [scannedData, setScannedData] = React.useState([])


  const [scannedModule, setScannedModule] = React.useState(0)

  const [isScanCompeted, setScanCompleted] = React.useState(false)
  const [isFirstScan, setFirstScan] = React.useState(true)

  const [isTestAssign, setTestAssign] = React.useState(false)


  const gotoNext = async () => {

     if(isTestAssign){
      try {

        if (finalData.length > 0) {
          // for (let item of finalData) {
          //     await mutatePostPannel({
          //     authAxios,
          //     serial_number:item.serial_number,
          //     test_type:item.test_type,
          //     status:item.status,
          //     module_intake:id,
          //   });
          // }
          await mutatePostBulkPannel({
            authAxios,
            scannedpanels:finalData,
            module_intake:id,
          });
                  
        }
        toast.success("Module date inserted successfully");
        history.push(`/operations_management/module_intake/intake/upload ${id}`);
      }
     
      catch (error) {
        toast.error(`Error uploading file: ${error}`);
      }
     }else{

      if(isScanCompeted){

        try {

          if (scannedData.length > 0) {
            await mutatePostBulkPannel({
              authAxios,
              scannedpanels:scannedData,
              module_intake:id,
            });
                    
          }
          toast.success("Module date inserted successfully");
          history.push(`/operations_management/module_intake/intake/upload ${id}`);
        }
        catch (error) {
          toast.error(`Error uploading file: ${error}`);
        }

      }else{
        toast.warning('Please add all Pannels')
      }
    
     }

  }


  if (isloading) {
    return (
      <Backdrop open={isloading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  
  function checkAlreadythere(serialNumber: string) {
    var isThere = false;
    {scannedData.map((item, index) => (
      item.serial_number===serialNumber?isThere=true:false
    ))}
    return isThere;
  }
    
  function onAutoAssign(): void {
    
    // const data = {
    //   intake_id:id,
    //   scanned : {scannedData}
    // }
    const testAssignedData = scannedData.map((item, index) => {
    
      var test_sequence_name = "No Test Available"
      var tesTypeID="";

      if(test_type.length>index){
        test_sequence_name = test_type[index].test_sequence_name;
        tesTypeID =  test_type[index].test_sequence_id;
      }
     
      return {
        serial_number: item.serial_number,
        test_sequence_name: test_sequence_name,
        test_sequence:tesTypeID,
        module_type:info.module_type,
        status: true
      };
    });

    setfinalData(testAssignedData)
    setTestAssign(true)

     const data = {
      intake_id:id,
      testAssignedData
    }
  }

  
  function gotoBack(): void {
    history.push(`/operations_management/module_intake/intake/`);
  }


 

  return (
    <div>
           <Typography variant="h4"> Intake Id : {id}</Typography>
      <Box margin={5}></Box>
      <Box padding={2}> 
             <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >  
        <Grid item xs={5}>
        <Typography variant="h4"> Scaned {scannedData.length} out 0f {info.number_of_modules}</Typography>
        </Grid>
        {
          isScanCompeted?<Grid item xs={5}>

          <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
          {/* <Button
            data-testid="submitCrateId"
            variant="contained"
            color="primary"
          > MANUALly assign TESts</Button> */}
          <Box flexGrow={0.4}></Box>
          <Button
            data-testid="submitCrateId"
            variant="contained"
            color="primary"
            onClick={onAutoAssign}
          > AUTO ASSIGN TESTS</Button>
          </Grid>
          </Grid>:
      <Grid item xs={5}>
        <Formik
          initialValues={{
            serialNumber: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
        
          onSubmit={async (values, { resetForm }) => {       
          try {
            if(values.serialNumber!=""){
              if(isFirstScan){
                const { data } = await authAxios.get(urls.intake.testType(info.projects,info.bom));
                setTestType(data.length==0?[]:data)
              }
               setFirstScan(false)
               if(checkAlreadythere(values.serialNumber)){
                toast.error('Already there');
                values.serialNumber=""
               }
               else{
                // const data  = await authAxios.get(urls.intake.scannItem(values.serialNumber));
                const data = {serial_number: values.serialNumber,module_type:info.module_type,test_sequence:"",status:true};
                setScannedData(prevScannedData => [...prevScannedData, data]);
                values.serialNumber=""
                scannedData.length===info.number_of_modules-1?setScanCompleted(true):false
               }
               resetForm();
               submitRef.current.focus();
           
            }else{
              toast.error('Please enter scan number');

            }



          } catch (err) {
              console.log(err.message)
          }
          }}
        >
          {({ errors, touched, submitForm, resetForm }) => {
            return (
              <div>
                <Form>
               
                      <Field
                        id="serialNumber"
                        name="serialNumber"
                        autoFocus
                        helperText={
                          touched.serialNumber ? errors.serialNumber : ""
                        }
                        error={
                          touched.serialNumber && Boolean(errors.serialNumber)
                        }
                        data-testid="serialNumber"
                        label="Serial Number"
                        margin="dense"
                        component={FormikTextField}
                        inputRef={submitRef}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />         
                </Form>
              </div>
            );
          }}
        </Formik>
        </Grid> 
        }

      </Grid>
      </Box>
      <ScannedTable data={finalData.length==0?scannedData:finalData}></ScannedTable>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-around"
        alignItems="center"
        style={{ marginTop: 32 }}
      >
        {/* <Grid item xs={2}>
          <Button
            data-testid="submitCrateId"
            variant="contained"
            color="primary"
            onClick={gotoBack}
          >
            Back
          </Button>
        </Grid> */}
        <Grid item xs={1}>
          <Button
            data-testid="submitCrateId"
            variant="contained"
            color="primary"
            onClick={gotoNext}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );



}

