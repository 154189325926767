import { useHistory } from "react-router-dom";
import { useCrateInfo } from "../../../intake/intakeMutations";
import { Backdrop, Box, ButtonBase, CircularProgress, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, LinearProgress, Typography } from "@material-ui/core";
import { Key, ReactChild, ReactFragment, ReactPortal } from "react";
import { useIntakeBySerialNumber } from "../../../testCommunication/common/testQueries";
import { DownloadPage } from "../../../common/services/downloadPage";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useFetchContext } from "../../../common/FetchContext";
import { useDownloadImageFromUrl } from "../../../common/services/fileServices";


export const IntakeImages =({ serialNumber }: any)=>{
    const history = useHistory();

    const {
        error: errorData,
        data: data,
        isLoading: isloding,
        isError: isErrorData,
    } = useIntakeBySerialNumber(serialNumber);
  
    if (isloding) {
        return (
                <LinearProgress color="primary" />
        );
    }else{
      // alert(JSON.stringify(moduleData.module_intake_details))
    }

    if(isErrorData){
        return(
            <Box padding={2}>
            <Typography variant="h4" color="primary">{"No data Found"}</Typography>
            </Box>   
        )
    }
    

    const downloadImage = async (url: any) => {
      var element = document.createElement("a");
      var fileUrl = url
      element.href = fileUrl;
      element.download = "image.jpg";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    return(
        <div>
        
    <Box padding={2}>
    <Grid container spacing={2}   alignItems="center" >
    <Typography variant="h5" color="primary">Crate Images</Typography>   
    </Grid>
    </Box>

    <Box sx={{ flexGrow: 1 }} padding={2}>
    <Grid container spacing={2}   alignItems="flex-start" >
        <Grid item xs = {3}   alignItems="flex-start"
         direction="column">
         <label>Uploaded Date: {data.crate_image_info[0]?data.crate_image_info[0].uploaded_date:"NA"}</label>
        </Grid>
        <Grid item xs = {6}   alignItems="flex-start"
         direction="column">
        </Grid>
    </Grid>
    </Box>

   
    <div>
          {data?.categorized_crate_images.map((category: { label_name: {} | null | undefined; images: any[]; }) => (

            <div>
              <h2>{category.label_name}</h2>
              {<h4>Description : {category.images[0]?.notes?category.images[0].notes:"NA"}</h4>            }
              <label></label>
              <ImageList cols={5} >
                {category.images.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`${item.image_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.image_path}?w=248&fit=crop&auto=format`}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      actionIcon={
                        <IconButton  onClick={() => {
                          downloadImage(item.image_path)
                        }}>
                          <SaveAltIcon color="primary"/>
                        </IconButton>
                      }

                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          ))}
        </div>

    <Box padding={2}>
    <Typography variant="h5" color="primary">Module Images</Typography>
    </Box>

    <Box sx={{ flexGrow: 1 }} padding={2}>
    <Grid container spacing={2}   alignItems="flex-start" >
        <Grid item xs = {3}   alignItems="flex-start"
         direction="column">
         <label>Intake Date: {data.module_image_info[0]?data.module_image_info[0].intake_date:"NA"}</label>
        </Grid>
        <Grid item xs = {3}   alignItems="flex-start"
         direction="column">
         <label>Recived Date: {data.module_image_info[0]?data.module_image_info[0].received_date:"NA"}</label>
        </Grid>
    </Grid>
    </Box>

    <div>
          {data?.categorized_module_images.map((category: { label_name: {} | null | undefined; images: any[]; }) => (

            <div>
              <h2>{category.label_name}</h2>
              {<h4>Description : {category.images[0]?.notes?category.images[0].notes:"NA"}</h4>            }
              <ImageList cols={5} >
                {category.images.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`${item.image_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.image_path}?w=248&fit=crop&auto=format`}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      actionIcon={
                        <IconButton  onClick={() => {
                          downloadImage(item.image_path)
                        }}>
                          <SaveAltIcon color="primary"/>
                        </IconButton>
                      }

                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          ))}
        </div>

    </div>
    

    )}

