import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useTheme } from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import { DatePicker, DateTimePicker } from "formik-material-ui-pickers";
import { toast } from "react-toastify";
import { useLocations, useModuleInsert, useProjectData } from "../../../intake/intakeQueries";
import { useHistory } from "react-router-dom";
import { useFetchContext } from "../../../common/FetchContext";
import { urls } from "../../../common/urls";
import { data } from "msw/lib/types/context";
import { Toast } from "react-toastify/dist/components";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";



interface Project {
  project: {
    id: number;
    project_number: string;
  }
}

export const NewModuleIntakeFirst = () => {
  const theme = useTheme();
  const history = useHistory();
  const { authAxios } = useFetchContext();

  const [selectedLocationData, setSelectedLocationDate] = React.useState([]);

  const [customerName, setCustomer] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [projectId, setProjectId] = React.useState("");
  const [locationName, setLoactionName] = React.useState("");
  const [locationId, setLoactionId] = React.useState("");


  const [bomList, setBomList] = React.useState([]);
  const [bomId, setBomId] = React.useState("");
  const [crateIds, setCrateIds] = React.useState([])
  const [crateId, setCrateId] = React.useState('')


  const [moduleLis, setModuleList] = React.useState([]);
  const [ModuleInfo, setModuleInfo] = React.useState({});

  const [modelName, setModelName] = React.useState("");
  const [moduleType, setModuleType] = React.useState("")
  const [voc, setVoc] = React.useState("")
  const [isc, setIsc] = React.useState("")
  const [vmp, setVmp] = React.useState("")
  const [imp, setImp] = React.useState("")
  const [mpp, setMpp] = React.useState("")
  const [system_voltage, setSystemVoltage] = React.useState("")
  const [dimention, setDimention] = React.useState("")
  const [expected_unit_count, setExpectedCount] = React.useState("");
  const [project_value, setProjectValue] = React.useState({});
  const [isSelected, setSelected] = React.useState(false);
  const [project_number, setProjectNumber] = React.useState("");
  const [lotId, setLotId] = React.useState("");
  const [pannels, setpannels] = React.useState("");

  const { mutateAsync: mutate } = useModuleInsert();
  const {
    error: errorProjects,
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
  } = useProjectData();

  // const onLocationSelection = async (location: any) => {
  //   try {
  //     const { data } = await authAxios.get(urls.intake.locationData);
  //     setSelectedLocationDate(data)
  // } catch (err) {
  //     console.log(err.message)
  //     toast.error(err.message);
  // }
  // }




  function onProjectSelected(data: any): void {
    setSelected(data ? true : false)
    setProjectValue(data ? data : "")
    setProjectId(data ? data.project.project_id : "")
    setProjectNumber(data ? data.project.project_number : "")
    setCustomer(data ? data.customer.customer_name : "")
    setCustomerId(data ? data.customer.customer_id : "")
    setBomList(data ? data.bom : [])
    setCrateIds(data ? data.crate_intake_ids : [])
    setLoactionName(data? data.location_data.location_name:"")
    setLoactionId(data? data.location_data.location_id:"")

    setModelName('')
    setModuleList([])
    setModuleType('')
    setVmp('')
    setImp('')
    setIsc('')
    setDimention('')
    setSystemVoltage('')
    setVoc('')
    setMpp('')
    setExpectedCount('')

  }


  const onBomSelected = async (bom: any) => {
    try {
      const { data } = await authAxios.get(urls.intake.getModuleIfo(projectId, bom));
      setBomId(bom)
      setModuleList(data)
    } catch (err) {
      console.log(err.message)
      toast.error('no model available for this work order');
      setBomId("")
      setModuleList([])
      setModelName('')
      setModuleType('')
      setVmp('')
      setImp('')
      setIsc('')
      setDimention('')
      setSystemVoltage('')
      setVoc('')
      setMpp('')
      setExpectedCount('')

    }
  }

  function onCrateSelected(data: any): void {
    setCrateId(data[0])
  }



  const onLotIdChanged = async (item: any) => {
    setLotId(item.target.value)
  }

  const onNumberChanged = async (item: any) => {
    setpannels(item.target.value)
  }
  const onModuleSelect = async (item: any) => {
    try {
      setModelName(item.model)

      const { data } = await authAxios.get(urls.intake.moduleInfo,{
        params: {
          module_type: item.model,
        }
      });
      setModuleType(data.module_property.module_technology_name)
      setVmp(data.module_property.vmp)
      setImp(data.module_property.imp)
      setIsc(data.module_property.isc)
      setDimention(data.module_property.module_width + 'x' + data.module_property.module_width)
      setSystemVoltage(data.module_property.system_voltage)
      setVoc(data.module_property.voc)
      setMpp(data.module_property.nameplate_pmax)
      setExpectedCount(data.expected_unit_count)

    } catch (err) {
      console.log(err.message)
      toast.error('module details not available');
      setModelName('')
      setModuleType('')
      setVmp('')
      setImp('')
      setIsc('')
      setDimention('')
      setSystemVoltage('')
      setVoc('')
      setMpp('')
      setExpectedCount('')

    }
  }

  if (isLoadingProjects) {
    return (
      <Backdrop open={isLoadingProjects}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isErrorProjects) {
    return (
      <Backdrop open={isErrorProjects}>
         <Typography variant="h3" align="center" display="inline" color="secondary">
            No Page Found
          </Typography>
      </Backdrop>
    );
  }

  const AutoCompleteField = ({ field, form, ...props }: FieldProps) => (
    <Autocomplete
      {...props}
      options={projects && projects}
      getOptionLabel={(option) => (option as Project).project.project_number}
      onChange={(event, value) => onProjectSelected(value)}
      renderInput={(params) => <TextField {...params} label="Projects" fullWidth />}
    />
  );

  const AutoCompleteFieldSelected = ({ field, form, ...props }: FieldProps) => (
    <Autocomplete
      {...props}
      options={projects && projects}
      value={project_value}
      getOptionLabel={(option) => (option as Project).project.project_number}
      onChange={(event, value) => onProjectSelected(value)}
      renderInput={(params) => <TextField {...params} label="Projects" fullWidth />}
    />
  );
  return (
    <div style={theme.container}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >

          <Grid item xs={12}>
            <Formik
              initialValues={{
                lot_id: "",
                number_of_modules: "",
                dateTimeReceived:new Date(),
                dateTimeIntake:new Date()
              }}

              onSubmit={async (values, { setSubmitting }) => {

                try {
                  if (crateId == "") {
                    toast.warning('Please select crate id')
                  } else if (lotId == "") {
                    toast.warning('Please enter loat id')
                  } else if (pannels == "") {
                    toast.warning('Please enter no. of modules')
                  } else if (moduleType == "") {
                    toast.warning('Please select model type')
                  }else  if (locationId == "") {
                    toast.warning('Location not available')
                  } 
                  else {
                    const response = await mutate({
                      authAxios,
                      lot_id: lotId,
                      bom: bomId,
                      module_type: modelName,
                      number_of_modules: pannels,
                      location: locationId,
                      projects: projectId,
                      customer: customerId,
                      newcrateintake: crateId,
                      intake_date:values.dateTimeIntake,
                      received_date:values.dateTimeReceived,
                    });
                    history.push(`/operations_management/module_intake/intake/intake ${response.id}`);
                  }

                } catch (error) {
                  toast.error(`Error uploading file: ${error}`);
                }
              }}>
              {({ errors, touched, submitForm, resetForm }) => {


                return (
                  <Form>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item xs={6}>
                        <Field
                          name="dateTimeReceived"
                          component={DateTimePicker}
                          format="YYYY-MM-DD"
                          style={{ marginBottom: 32 }}
                          label="Received Date"
                          inputVariant="outlined"
                          fullWidth
                          data-testid="dateReceivedId"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="dateTimeIntake"
                          component={DateTimePicker}
                          format="YYYY-MM-DD"
                          style={{ marginBottom: 32 }}
                          label="Intake Date"
                          inputVariant="outlined"
                          fullWidth
                          data-testid="dateIntakeId"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="project"
                          type="text"
                          name="project"
                          fullWidth
                          component={isSelected ? AutoCompleteFieldSelected : AutoCompleteField}
                          style={{ marginBottom: 32 }}
                          label="Projects"
                          data-testid="project"
                        >
                          {/* {projects?.map((data: any) => (
                          <MenuItem
                            key={data.project.id}
                            value={data.project.project_number}
                            onClick={() => onProjectSelected(data)}>
                            {data.project.project_number}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                     
                      <Grid item xs={6}>

                        <TextField
                          id="lot_id"
                          type="text"
                          name="lot_id"
                          fullWidth
                          style={{ marginBottom: 32 }}
                          onChange={(value) => onLotIdChanged(value)}
                          label="Lot ID"
                          data-testid="lot_id"
                        >
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="location"
                          type="text"
                          name="location"
                          fullWidth
                          component={TextField}
                          value={locationName}
                          style={{ marginBottom: 32 }}
                          label="Location"
                          data-testid="location"
                          InputProps={{
                            readOnly: true, 
                          }}>
                        </Field>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Field
                          id="customer"
                          type="null"
                          name="customer"
                          fullWidth
                          component={TextField}
                          value={customerName}
                          style={{ marginBottom: 32 }}
                          label="Customer"
                          data-testid="customeer"
                        >
                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          id="manufacturer"
                          type="text"
                          name="Manufacturer"
                          fullWidth
                          component={TextField}
                          value={customerName}
                          style={{ marginBottom: 32 }}
                          label="Manufacturer"
                          data-testid="manufacturer"
                        >

                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          id="bom"
                          type="text"
                          name="bom"
                          select={true}
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Bom"
                          data-testid="bom"
                        >
                          {bomList?.map((data: any) => (
                            <MenuItem
                              value={data}
                              onClick={() => onBomSelected(data)}>
                              {data}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          id="crate_id"
                          type="text"
                          name="crate_id"
                          select={true}
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Crate ID"
                          data-testid="bom"
                        >
                          {crateIds?.map((data: any) => (
                            <MenuItem
                              value={data[0]}
                              onClick={() => onCrateSelected(data)}>
                              {data[1]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          id="module_type"
                          type="text"
                          name="module_type"
                          select={true}
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Module Type"
                          data-testid="module_type"
                        >
                          {moduleLis?.map((item: any) => (
                            <MenuItem
                              value={item.model}
                              onClick={() => onModuleSelect(item)}>
                              {item.model}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <div>
                      <Box paddingTop={5} paddingBottom={5}>
                        <Typography variant="subtitle1" color="secondary">
                          Module Information
                        </Typography>
                      </Box>

                    </div>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}

                          style={{ marginBottom: 32 }}
                          label="Cell Type"
                          data-testid="model_type"
                          value={moduleType}
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Module Dimensions (mm)"
                          value={dimention}
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Mpp"
                          value={mpp}
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>


                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Voc"
                          value={voc}
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          value={isc}
                          style={{ marginBottom: 32 }}
                          label="Isc"
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          value={vmp}
                          style={{ marginBottom: 32 }}
                          label="Vmp"
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          value={imp}
                          style={{ marginBottom: 32 }}
                          label="Imp"
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="model_type"
                          type="text"
                          name="Model type"
                          fullWidth
                          component={TextField}
                          value={system_voltage}
                          style={{ marginBottom: 32 }}
                          label="Rated Voltage"
                          data-testid="model_type"
                        >
                        </Field>
                      </Grid>
                      <Grid item xs={6}>


                        <Field
                          id="expected_number_of_modules"
                          name="expected_number_of_modules"
                          fullWidth
                          component={TextField}
                          // helperText={touched.number_of_modules ? errors.number_of_modules : ""}
                          // error={touched.number_of_modules && Boolean(errors.number_of_modules)}   
                          style={{ marginBottom: 32 }}
                          value={expected_unit_count}
                          label="Expected No. of Modules"
                          data-testid="expected_number_of_modules"
                        >
                        </Field>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="number_of_modules"
                          type="number"
                          name="number_of_modules"
                          fullWidth
                          style={{ marginBottom: 32 }}
                          onChange={(value) => onNumberChanged(value)}
                          label="No. of Modules"
                          data-testid="number_of_modules"
                        >
                        </TextField>
                      </Grid>
                    </Grid>




                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      style={{ marginTop: 32 }}
                    >
                      <Grid item xs={2}>
                        <Button
                          data-testid="submitCrateId"
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}













