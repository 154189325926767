import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Grid,
  useTheme,
  Typography,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { processErrorOnMutation } from "../../../../util/errorMessaging";
import { DateTimePicker } from "formik-material-ui-pickers";
import { useProjects } from "../../../intake/intakeQueries";
import { useFetchContext } from "../../../common/FetchContext";
import { useCreateCrateNew } from "../../../intake/intakeMutations";
import moment from "moment";
import { useCustomers } from "../../projectManagementQueries";
import { values } from "lodash";



export const CrateIntake = () => {
  const history = useHistory();
  const theme = useTheme();
  const { authAxios } = useFetchContext();

  const [projectList, setProject] = React.useState([])
  const[customerName, setCustomer] = React.useState("")

  const { mutateAsync: mutateCreateCrate } = useCreateCrateNew();

  //   const {
  //   error: errorProjects,
  //   data: projects,
  //   isLoading: isLoadingProjects,
  //   isError: isErrorProjects,
  // } = useProjects();
  const {
    error: errorCustomer,
    data: customers,
    isLoading: isCustomerLoading,
    isError: isErrorCustomer,
  } = useCustomers();

  function onCustomerSelect(customerItem: any): void {
    setProject(customerItem.project_numbers)
    setCustomer(customerItem.name)
  }

  if(isCustomerLoading){
    return(
      <Backdrop open={true}>
        <CircularProgress></CircularProgress>
      </Backdrop>
    )
  }else{
  return (
    <div style={theme.container}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Formik
              initialValues={{
                description:"",
                customer:"",
                manufacturer:"",
                project:"",
                dateTimeReceived:new Date()
              }}
              validationSchema={Yup.object({
                customer: Yup.string().required("customer name requied"),
                description: Yup.string().required("crate name requied"),

                // manufacturer: Yup.string().required("manufacturer name requied"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
            var date = ""
            if(values.dateTimeReceived==null){
              date = ""
            }else{
              date =moment(values.dateTimeReceived).format("YYYY-MM-DD")
            }
                  
                  console.log("Form values", JSON.stringify(values));

            const newCrateData = await mutateCreateCrate({
              authAxios,
              crate_name:values.description,
              customer: values.customer,
              manufacturer: values.customer,
              project: values.project,
              crate_intake_date: date,
            
            });

            setSubmitting(false)
  
            history.push(`/operations_management/crates/crate_intake/${newCrateData.id}`);
                } catch (error) {
                  toast.error(`Error while trying to send email link`);
                  processErrorOnMutation(error);
                }
              }}>
              {({ errors, touched, submitForm, resetForm }) => {
               

                return (
                  <Form>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                      <Field
                      name="dateTimeReceived"
                      component={DateTimePicker}
                      format="YYYY-MM-DD"
                      style={{ marginBottom: 32 }}
                      label="Crate Date & Time"
                      inputVariant="outlined"
                      fullWidth
                      data-testid="dateReceivedId" />
                      </Grid>
                      <Grid item xs={12}>
                      <Field
                        name="description"
                        helperText={touched.description ? errors.description : ""}
                        error={touched.description && Boolean(errors.description)}
                        component={TextField}
                        data-testid="description"
                        label="Crate Name"
                        style={{ marginBottom: 32 }}
                        placeholder="Enter Crate Name"
                        fullWidth
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          id="customer"
                          helperText={touched.customer ? errors.customer : ""}
                          error={touched.customer && Boolean(errors.customer)}
                          type="text"
                          name="customer"
                          fullWidth
                          select={true}
                          component={TextField}
                          
                          style={{ marginBottom: 32 }}
                          label="Customer"
                          data-testid="customer">

                         {customers?.map((customerItem: any) => (
                          <MenuItem key={customerItem.id} value={customerItem.id} onClick={() => onCustomerSelect(customerItem)}>
                            {customerItem.short_name}
                          </MenuItem>
                        ))}

                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          id="manufacturer"
                          type="text"
                          name="manufacturer"
                          fullWidth
                          value={customerName}
                          component={TextField}
                          helperText={touched.manufacturer ? errors.manufacturer : ""}
                          error={touched.manufacturer && Boolean(errors.manufacturer)}
                          style={{ marginBottom: 32 }}
                          label="Manufacturer"
                          data-testid="manufacturer"
                        ></Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          id="project"
                          type="text"
                          select={true}
                          name="project"
                          fullWidth
                          component={TextField}
                          helperText={touched.project ? errors.project : ""}
                          error={touched.project && Boolean(errors.project)}
                          style={{ marginBottom: 32 }}
                          label="Project"
                          data-testid="project">
                            {projectList?.map((project: any) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.number}
                    </MenuItem>
                  ))}
                          </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justify="space-around"
                          alignItems="center"
                          style={{ marginTop: 32 }}
                        >
                          <Grid item xs={2}>
                            <Button
                              data-testid="submitForgotPassword"
                              variant="contained"
                              color="primary"
                              onClick={submitForm}
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => resetForm()} >
                              Clear
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
};


















// export const CrateIntake = () => {
//   const theme = useTheme();
//   const isFetching = useIsFetching();
//   const history = useHistory();
//   const { authAxios } = useFetchContext();

//   const { mutateAsync: mutateCreateCrate } = useCreateCrateNew();


//   const [files, setFiles] = React.useState([]);


//   const handleFileChange = (uploadedFiles: any) => {
//     setFiles(uploadedFiles);
//   };




//     return (
//       <>
//         <Formik
//           initialValues={{
//             customer: "ea",
//             manufacturer: "dad",
//             project:"ada",
//             textData:"",
//             dateTimeReceived:new Date()
  
//           }}


//           // validationSchema={Yup.object({
//           //   customer: Yup.string().required("Field must be required"),
//           //   manufacturer: Yup.string().required("Field must be required"),
//           // })}
          
          
//           onSubmit={async (values, { setSubmitting }) => {
  
//             console.log("Form values", JSON.stringify(values));

//             var date = ""
//             if(values.dateTimeReceived==null){
//               date = ""
//             }else{
//               date =moment(values.dateTimeReceived).format("YYYY-MM-DD")
//             }
  
//             console.log("Form values", JSON.stringify(values));
  
//             // const newCrateData = await mutateCreateCrate({
//             //   authAxios,
//             //   customer: values.customer,
//             //   manufacuturer: values.manufacturer,
//             //   project: values.project,
//             //   crate_intake_date: date,
            
//             // });

//             // setSubmitting(false)
  
//             // history.push(`/operations_management/crate_intake/image_upload/${newCrateData.id}`);
  
  
//           }}
//         >
//           {({ errors, touched, submitForm, resetForm }) => (
//             <Form>
//               <Container maxWidth="sm">
            

              
//                 <Field
//                   name="customer"
//                   helperText={touched.customer ? errors.customer : ""}
//                   error={touched.customer && Boolean(errors.customer)}
//                   component={TextField}
//                   data-testid="customerId"
//                   label="Customer Name"
//                   style={{ marginBottom: 32 }}
//                   placeholder="Customer Name"
//                   fullWidth
//                   margin="dense"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
  
//                 <Field
//                   name="manufacturer"
//                   component={TextField}
//                   helperText={touched.manufacturer ? errors.manufacturer : ""}
//                   error={touched.manufacturer && Boolean(errors.manufacturer)}
//                   data-testid="manufacturerId"
//                   label="Manufacturer Name"
//                   style={{ marginBottom: 32 }}
//                   placeholder="Manufacturer Name"
//                   fullWidth
//                   margin="dense"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
  
  
//                 <Field
//                   id="project"
//                   type="text"
//                   name="project"
//                   select={true}
//                   fullWidth
//                   component={TextField}
//                   helperText={touched.project ? errors.project : ""}
//                   error={touched.project && Boolean(errors.manufacturer)}
//                   style={{ marginBottom: 32 }}
//                   label="Project (Optional)"
//                   data-testid="projectId"
//                 >
                  
//                 </Field>
               
//                 <Grid
//                   container
//                   spacing={3}
//                   direction="row"
//                   justify="space-around"
//                   alignItems="center"
//                   style={{ marginTop: 32 }}
//                 >
//                   <Grid item xs={2}>
//                     <Button
//                       data-testid="submitCrateId"
//                       variant="contained"
//                       color="primary"
//                       onClick={submitForm}
//                     >
//                       Submit
//                     </Button>
//                   </Grid>
//                   <Grid item xs={2}>
//                     <Button variant="contained" color="secondary">
//                       Clear
//                     </Button>
//                   </Grid>
          
//                 </Grid>
//               </Container>
//             </Form>
//           )}
//         </Formik>
//       </>
//     );
//   }
// }
