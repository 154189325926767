import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { EditProjectItems } from "../projectManagement/components/myprojects/EditProjectItems";
import CloseIcon from '@material-ui/icons/Close';

import {Link as MuiLink, useTheme } from "@material-ui/core/";
import { constant } from "lodash";
import { getLastEditProjectDetails } from "../projectManagement/projectManagementQueries";
import { LastEditedProjectItems } from "../projectManagement/components/myprojects/LastEditedProjectItems";


export const LastEditedProject = ({ id }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(!open);
  };


  return (
    <>
      <MuiLink component={Button} onClick={handleClose}>
        Last update details
        </MuiLink>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}>
        <DialogTitle id="id">
          <div>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{"Project Edit By"}</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider variant="fullWidth" />
          <LastEditedProjectItems id={id}/>

        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  )
};




