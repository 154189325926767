import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, colors, styled } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom";
import { useModuleInfo } from "../../../intake/intakeQueries";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { Key } from "react";
import { useCrateInfo, useCrateUpdate, useUpdateCrateFile } from "../../../intake/intakeMutations";
import { Add, AddAPhotoRounded, CloudUpload, Edit, RoomOutlined } from "@material-ui/icons";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Field, Form, Formik } from "formik";
import React, { useState, useRef } from "react";
import { DatePicker } from "formik-material-ui-pickers";
import { toast } from "react-toastify";
import { useFetchContext } from "../../../common/FetchContext";
import ImageUpload from "./ImageUpload";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "primary",
  padding: theme.spacing(2),
  fontSize:14,
  borderRadius:10,
  color: "secodary",
}));



var moduleInfo;

export const CrateInfo = () =>{
  const history = useHistory();


  const { authAxios } = useFetchContext();

  const { id } = useParams() as {
    id: string;
  };
  const [open, setOpen] = React.useState(false)
  const [openImage, setOpenImage] = React.useState(false)
  const [image, setImage] = React.useState("")
  const [files, setFiles] = React.useState([]);
  const [uploading, setUploading] = useState(false);
  const [labelName, setLabel] = React.useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
  };


  function goto(): void {
    history.push(`/operations_management/crates/crate_intake/${id}`);

  }


  
 const handleImage = async (item: any) => {
  console.log(item)
    setImage(item.id)
    setLabel(item.label_name)
    fileInputRef.current?.click()
   
  };

  const { mutateAsync: mutate } = useCrateUpdate();
  const { mutateAsync: mutateimage } = useUpdateCrateFile();

  const handleFileChange = async (uploadedFiles: any) => {
    setFiles(uploadedFiles);
    setUploading(true); // Start uploading
    try {
      if (uploadedFiles.length > 0) {
        for (let file of uploadedFiles) {
          setOpenImage(false)
          await mutateimage({
            authAxios,
            file: file,
            id: id,
            image_id: image,
            label: labelName
          });

        }
        toast('Image uploaded successfully!');
      }
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Image uploaded faild!');
      setOpenImage(false)

    }
    finally {
      setUploading(false); // End uploading
      window.location.reload(); // This might need reconsideration based on your application flow
    }

  };
  

  const {
      error: errorData,
      data: crateInfo,
      isLoading: isloding,
      isError: isErrorData,
  } = useCrateInfo(id);

  if(isErrorData){
    return ( <ErrorMessage error={errorData} />);
  }
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
   
    const uploadedFiles = event.target.files;
    if (uploadedFiles && uploadedFiles.length > 0) {
      handleFileChange(Array.from(uploadedFiles));
    }

  };
  if (isloding) {
      return (
          <Backdrop open={isloding}>
              <CircularProgress color="inherit" />
          </Backdrop>
      );
  }else{
    // alert(JSON.stringify(moduleData.module_intake_details))
  }

  



  return(
    <Box sx={{ flexGrow: 1 }} padding={2}>
    <Grid container spacing={2}   alignItems="flex-start" >
        <Grid item xs = {12}>
        <Box padding={2}>
        <Typography variant="h4" color="primary">Crate Info
        <IconButton onClick={handleClickOpen}><Edit color="primary" /></IconButton>
 
        </Typography>

        </Box>

        </Grid>
        
        <Grid item xs = {12}   alignItems="flex-start"direction="column">
        <Item >
         <Grid  container spacing={2}>
         <Grid item xs={6}>
          <label>Crate Id: {id}</label>
          
          </Grid> 
          <Grid item xs={6}>
          <label>Project Number: {crateInfo.project_number}</label>
          </Grid>
          <Grid item xs={6}>
          <label>Customer Name: {crateInfo.customer_name}</label>
          </Grid>
          <Grid item xs={6}>
          <label>Manufacturer Name: {crateInfo.manufacturer_name}</label>
          </Grid>
          <Grid item xs={6}>
          <label>Intake Date: {crateInfo.crate_intake_date}</label>
          </Grid>
          <Grid item xs={6}>
          <label>Intake By: {crateInfo.created_by}</label>
          </Grid> 
         </Grid>
        </Item>
       
        </Grid>
    
    </Grid>

   
    <Box padding={2}>
    <Typography variant="h4" color="primary">Images  <IconButton  onClick={() => goto()}><AddAPhotoRounded color="primary"></AddAPhotoRounded></IconButton></Typography>
    </Box>


    <div>
      {crateInfo?.categories_of_crate_images.map((category: { label_name: {} | null | undefined; images: any[]; }) => (

        <div>
          <h2>{category.label_name}</h2>
          {<h4>Description : {category.images[0]?.notes?category.images[0].notes:"NA"}</h4>            }
          <ImageList  cols={5} >
          {category.images.map((item) => (
          <ImageListItem key={item.id}>
          <img
            srcSet={`${item.image_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.image_path}?w=248&fit=crop&auto=format`}
            loading="lazy"
          />
             <ImageListItemBar
                      actionIcon={
                        <IconButton onClick={() => handleImage(item)}>
                          <Edit color="primary" />
                          {uploading ? image===item.id ? <CircularProgress size={24} />:'':''}
                          </IconButton>
                        
                      }
                      />
                          <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*,.pdf"
                            
                            onChange={handleImageUpload}
                          />
        </ImageListItem>
      ))}
    </ImageList>
        </div>
      ))}
    </div>


    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Crate Info</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
            intake_date:crateInfo.crate_intake_date
            }}

            onSubmit={async (values, { setSubmitting }) => {

              try {
                  
                var date = ""
                if(values.intake_date==null){
                  date = ""
                }else{
                  date =moment(values.intake_date).format("YYYY-MM-DD")
                }
                await mutate({
                  authAxios,
                  newcrateintake:id,
                  crate_intake_date:date
                });

                setSubmitting(false);

                handleClose();                  
                toast.success("Project was succesfully changed");
                window.location.reload();
                
              } catch (error) {
                toast.error("error data");
              }

            }}
          >
            {({ errors, touched, submitForm, resetForm }) => (
              <Form>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item sm={12}>
                    <Field
                      id="intake_date"
                      name="intake_date"
                      component={DatePicker}
                      format="YYYY-MM-DD HH:MM"
                      style={{ marginBottom: 32 }}
                      label="Intake Date"
                      inputVariant="outlined"
                      data-testid="intake_date"
                      fullWidth
                    />
                  </Grid>

                  <Button
                      data-testid="submitCrateId"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={submitForm}
                    >
                      Submit
                    </Button>

                </Grid>
           
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
    </Dialog>



    <Dialog open={openImage} onClose={handleClose}>
        <DialogTitle>Image Upload</DialogTitle>
        <DialogContent>
        <DropzoneArea
                        acceptedFiles={["image/*", ".pdf"]}
                        onChange={(files) => handleFileChange(files)}
                        filesLimit={1}
                        maxFileSize={12000000}
                        data-testid="fileUploadCrate"
                      />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  </Box>
  )
}