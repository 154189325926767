export const STEP_RESULT_TEST_START_NAME = "Test Start";
export const STEP_RESULT_TEST_END_NAME = "Test End";
export const STEP_RESULT_TEST_PAUSE_NAME = "Test Pause";
export const STEP_RESULT_TEST_RESUME_NAME = "Test Resume";
export const MEASUREMENT_RESULT_START_TIME_NAME = "Start Time";
export const MEASUREMENT_RESULT_END_TIME_NAME = "End Time";
export const MEASUREMENT_RESULT_PAUSE_TIME_NAME = "Pause Time";
export const MEASUREMENT_RESULT_RESUME_TIME_NAME = "Resume Time";
export const STRESSOR_CHECK_IN_MODE = 0;
export const STRESSOR_CHECK_OUT_MODE = 1;
export const STRESSOR_CHECK_PAUSE_MODE = 2;
export const STRESSOR_CHECK_RESUME_MODE = 3;
export const STRESSOR_NOT_APPLY_METADATA = -1;
