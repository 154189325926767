import { Button, Grid, LinearProgress } from "@material-ui/core";
import { useDefaultColumn } from "../../../util/filter";
import { useVerifyResultData } from "../dataEgressQueries";
import { ErrorMessage } from "../../common/ErrorMessage";
import React from "react";
import { AxiosInstance } from "axios";
import { useFetchContext } from "../../common/FetchContext";
import { urls } from "../../common/urls";

export interface Note {
    authAxios: AxiosInstance;
  }

export const EgressStatus = ({ id }: any) => {
    const defaultColumn = useDefaultColumn();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isFirstClick, setIsFirstClick] = React.useState(true);
    const [data, setData] = React.useState<any>({});
    const [err, setErr] = React.useState('');
    const { authAxios } = useFetchContext();


    const getProjectNotes = async (): Promise<any> => {
        try{
          var data = await authAxios.get(urls.procedureResults.count(id));
          setIsLoading(false)
          setData(data.data); 
        }catch(err){
          setIsLoading(false)
          setErr('error');
        }
      };


    
      if (isLoading) {
        return (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        );
      }
    
      if (err) {
        return <ErrorMessage error={err} />;
      }
    
      if(isFirstClick){
        return(<Button color="inherit" onClick={()=>{
          setIsFirstClick(false)
          setIsLoading(true)
          getProjectNotes()
        }} fullWidth>view</Button>)
      }else{
        return (<label> {data.verified_by}/{data.completed}/{data.total} </label>);
      }

}
