import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Box, Button, Container, Grid, Icon, Input, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Edit, Scanner } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { AddNewNote } from "../../../common/NoteOnNewDialog";
import { DateTimePicker } from "formik-material-ui-pickers";
import { DropzoneArea } from "material-ui-dropzone";
import { useFetchContext } from "../../../common/FetchContext";
import { toast } from "react-toastify";
import { processErrorOnMutation } from "../../../../util/errorMessaging";
import { usePostModulesFile } from "../../../common/services/fileServices";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import moment from "moment";

export const ModuleIntakeUpload = () => {
  const history = useHistory();

  const { id } = useParams() as {
    id: string;
  };

  const { authAxios } = useFetchContext();
  const { mutateAsync: mutatePostFile } = usePostModulesFile();
  const [files, setFiles] = React.useState([]);



  function gotoNext(): void {
  }

  function gotoBack(): void {
    throw new Error("Function not implemented.");
  }
  const handleFileChange = (uploadedFiles: any) => {
    setFiles(uploadedFiles);
  };


  const catagories = [
    'Full front side of the module',
    'Top right corner of the module',
    'Full rear side of the module',
    'Nameplate',
    'Junction box',
    'Leads'
  ];


  return (
    <div>
      <Typography variant="h4"> Intake Id : {id}</Typography>
      <Box margin={5}></Box>
      <Formik
        initialValues={{
          category: "",
          notes:"",
          dateTimeReceived:new Date(),
          dateTimeIntake:new Date()
        }}
        onSubmit={async (values, { setSubmitting }) => {

          try {
            var date = ""
            var date1=""
            if(values.dateTimeReceived==null){
              date = ""
            }else{
              date =moment(values.dateTimeReceived).format("YYYY-MM-DD")
            }

            if(values.dateTimeIntake==null){
              date1 = ""
            }else{
              date1 =moment(values.dateTimeIntake).format("YYYY-MM-DD")
            }

            console.log("Form values", JSON.stringify(values));

            
            if (files.length > 0) {
              for (let file of files) {
                const postedFile = await mutatePostFile({
                  authAxios, file,
                  moduleintake: id,
                  label_name: values.category,
                  notes:values.notes,
                  intake_date:date1,
                  received_date:date,
                });

              }
            }
            toast.success(
              values.category +" imaged uploaded successfully"
            );

            window.location.reload()
            setSubmitting(false)

          } catch (error) {
            processErrorOnMutation(error);
          }
        }}
      >
        {({ errors, touched, submitForm }) => (
          <Form>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={8}>

                <Field
                  id="category"
                  type="text"
                  name="category"
                  select={true}
                  fullWidth
                  component={TextField}
                  style={{ marginBottom: 32 }}
                  label="Select Category"
                  data-testid="category"
                >
                  {catagories.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>

              </Grid>
              <Grid item xs={8}>


                <DropzoneArea
                  acceptedFiles={["image/*", ".pdf"]}
                  onChange={(files) => handleFileChange(files)}
                  filesLimit={
                    process.env.REACT_APP_FILE_LIMIT
                      ? parseInt(process.env.REACT_APP_FILE_LIMIT)
                      : 20
                  }
                  maxFileSize={12000000}
                  data-testid="fileUploadCrate"
                />
              </Grid>

              <Grid item xs={8}>
              <Field
                  id="notes"
                  type="text"
                  name="notes"
                  fullWidth
                  outlined
                  component={TextField}
                  style={{ marginBottom: 32 }}
                  label="Enter Notes"
                  data-testid="notes"
                />
         
              </Grid>
              <Grid item xs={8}>
                <Button
                  data-testid="submitCrateId"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

          </Form>
        )}
      </Formik>
    </div>
  );

}


