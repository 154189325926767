import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Button, Paper,  Table,  TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatDate } from "../../../common/formatDate";
import { useHistory } from "react-router-dom";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import { generalFilter } from "../../../../util/filter";



  
export const ModuleIntakeTable = ({data}:any)=>{

  const history = useHistory();


  function gotoNext(step: any, id: any): void {
    if(step==='step 2'){
      history.push(`/operations_management/module_intake/intake/upload ${id}`);
    }else{
      history.push(`/operations_management/module_intake/intake/intake ${id}`);
    }
  }
     

  function gotoView(id: any): void {
    history.push(`/operations_management/module_intake/module ${id}`);

  }


  const columns = React.useMemo(
    () => [
      {
        id: "Id",
        accessor: "id",
      },
      {
        id:"Project",
        accessor:"project_number",
        filter: generalFilter,
      },
      {
        id:"Bom",
        accessor:"bom"
      },
      {
        id:"Crate Name",
        accessor:"crate_name"
      },
      {
        id:"Customer",
        accessor:"customer_name"
      },
      {
        id:"Manufacturer",
        accessor:"manufacturer_name"
      },
      {
        id:"Intake Date",
        accessor:"intake_date",
        Cell: ({ cell, row }: any) => {
          return formatDate(cell.value)
        }
      },
      {
        id:"Intake By",
        accessor:"intake_by"
      },
      {
        id:"isComplete",
        accessor:"is_complete"
      },
      {
        id:"Step",
        accessor:"steps"
      },
      
      {
        id:"none",
        disableFilters: true,
        accessor: "is_complete",
        Cell: ({ cell, row }: any) => {
          return row.values.isComplete?<a href="#" style={{ color: 'orange' }} onClick={() =>gotoView(row.values.Id)}>View</a> :<a href="#" style={{ color: 'orange' }} onClick={() => gotoNext(row.values.Step, row.values.Id)}>Continue</a>
        }
      }
    
    ],
    []
  );




  return (
    <>
      {data && (
        <LSDBCoreTable
          columns={columns}
          data={data}
          hiddenColumns={["Id","isComplete","Step"]}
        />
      )}
    </>
  );

};

   
    


