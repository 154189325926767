import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Button, Paper,  Table,  TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatDate } from "../../../common/formatDate";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import { generalFilter, useDefaultColumn } from "../../../../util/filter";



  
export const CrateIntakeTable = ({data}:any)=>{
  const history = useHistory();


  function gotoView(id: any): void {
    history.push(`/operations_management/crates/Crate ${id}`);

  }
  const columns = React.useMemo(
    () => [
     
      {
        id:"Crate Name",
        accessor:"crate_name",
        filter: generalFilter,
      },
      {
        id:"Customer",
        accessor:"customer_name",
        filter: generalFilter,
      },
      {
        id:"Manufacture",
        accessor:"manufacturer_name",
        filter: generalFilter,
      },
      {
        id:"Project",
        accessor:"project_number",
        filter: generalFilter,
      },
      {
        id:"Intake By",
        accessor:"created_by",
        filter: generalFilter,
      },
      {
        id:"Intake Date",
        accessor:"crate_intake_date",
        filter: generalFilter,
        Cell: ({ cell, row }: any) => {
          return formatDate(cell.value)
        }
      },
      {
        id:"Action",
        disableFilters: true,
        accessor: "id",
        Cell: ({ cell, row }: any) => {
          console.log(cell.value)
          return <a href="#" style={{ color: 'orange' }} onClick={() =>gotoView(cell.value)}>View</a> 
        }
      }
     
    
    ],
    []
  );




  return (
    <>
      {data && (
        <LSDBCoreTable
          columns={columns}
          data={data}
          hiddenColumns={["Id","Step"]}
        />
      )}
    </>
  );
    
    
}

