import React from "react";
import {
  IconButton,
  Link as MuiLink,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
} from "@material-ui/core";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";
import { Link } from "react-router-dom";
import { generalFilter, useDefaultColumn } from "../../../../util/filter";
import { formatDate } from "../../../common/formatDate";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { toast } from "react-toastify";
import { useInventoryUpdate } from "../../../intake/intakeQueries";
import { useFetchContext } from "../../../common/FetchContext";
import { values } from "lodash";
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import { Cached, Error, History, Pause, Save } from "@material-ui/icons";
import HistoryTimeLine from "./HistoryTimeLine";
import LSDBPaginatedTable from "../../../common/LSDBPaginatedTable";
import { LSDBCoreTableWithoutDropDown } from "../../../common/LSDBCoreTableWithoutDropDown";
import { urls } from "../../../common/urls";
import moment from "moment";


const dateFormatter = (dateToFormat: Date) => {
  return `${dateToFormat.toDateString()} ${dateToFormat
    .getUTCHours()
    .toString()
    .padStart(2, "0")}:${dateToFormat
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}:${dateToFormat
        .getUTCSeconds()
        .toString()
        .padStart(2, "0")}`;
};

export const ModuleInventoryTablePage = ({ data, dispositions, locations }: any) => {
  const defaultColumn = useDefaultColumn();
  const [open, setOpen] = React.useState(false);
  const [selectedSerial, setSelectedSerial] = React.useState('');

  const { mutateAsync: mutate } = useInventoryUpdate();
  const { authAxios } = useFetchContext();


  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, rowData: any) => {
    e.preventDefault(); // Prevent actual link navigation
    setOpen(true); // Open the dialog
  };
  const [reloadHistory, setReloadHistory] = React.useState(false);

  const handleClose = () => {
    setOpen(false); // Close the dialog

    setReloadHistory((prev) => !prev); 
  };

  const getDownloads = async (): Promise<any> => {
    const response = await authAxios.get(urls.units.locationHistoryDownload(selectedSerial), {
      responseType: 'blob'
    });

    const downloadUrl = URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${moment().format("MMM-DD-YYYY-HHMMSS")}`;
    document.body.appendChild(a);
    a.click();
};

  const columns = React.useMemo(
    () => [
      {
        id: "Id",
        accessor: "id",
      },
      {
        id:"module_height",
        accessor:'module_specification.module_height'
      },
      {
        id: "Serial",
        accessor: "serial_number",
        Header:'Serial Number',
        Cell: ({ cell, row }: any) => (
          <MuiLink component={Link} to={`/operations_management/module_inventory/${cell.value}`}>
            {cell.value}
          </MuiLink>
        ),
      },
      {
        id: "Number",
        accessor: "project_number",
        Header:'Project Number',
      },
      {
        id: "Work Order",
        Header:'Work Order',
        accessor: "workorder_name",
      },
      {
        id: 'Customer',
        Header:'Customer',
        accessor: 'customer_name'
      },
      {
        id: 'Manufacturer',
        Header:'Manufacturer',
        accessor: 'customer_name'
      },
    
      {
        Header:'Location',
        id: "Location",
        accessor: "location_id",
        filter: generalFilter,
        Cell: ({ row }: any) => {
          // Get the serial number from the current row
          const serial = row.values.Serial;

          // onChange handler for the Select dropdown
          const handleChange = async (e: React.ChangeEvent<{ value: any }>) => {
            const newLocation = e.target.value as string;

            console.log("Form values", serial, newLocation);
            await mutate({
              authAxios,
              serial_number: serial, // Pass serial number to the API
              location: newLocation, // Pass new location to the API
            });
            toast.success("Project disposition status updated successfully");
          };

          function onHistoryClick(Serial: any) {
            setOpen(true);
            setSelectedSerial(Serial)
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                labelId="location_select"
                id="location_select"
                disableUnderline
                defaultValue={row.values.Location}
                label="Location"
                onChange={handleChange}
              >
                {locations?.map((location: any) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
              <IconButton onClick={() => { onHistoryClick(row.values.Serial); }}><History color="primary"></History></IconButton>
            </div>
          );
        },
      },
      {
        Header:'Intake Location',
        accessor: 'intake_location'
      },

      {
        Header:'Eol Disposition',
        id: "Disposition",
        accessor: "eol_disposition",
        filter: generalFilter,
        Cell: ({ row }: any) => {
          const serial = row.values.Serial;
          const handleChange = async (e: React.ChangeEvent<{ value: any }>) => {
            var newDisposition = e.target.value as string;
            console.log("Form values", serial);
            await mutate({
              authAxios,
              serial_number: serial,
              eol_disposition: newDisposition,
            });
            toast.success("Project disposition status updated successfully");
          };

          return (
            <Select
              labelId="disposition_select"
              id="disposition_select"
              disableUnderline
              defaultValue={row.values.Disposition}
              label="Disposition"
              onChange={handleChange}>
              {dispositions?.map((disposition: any) => (
                <MenuItem key={disposition.id}
                  value={disposition.id}>
                  {disposition.name}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        Header:'CloseOut Date',
        id: "CloseOut Date",
        accessor: "project_closeout_date",
        Cell: ({ cell }: any) =>
          cell.value ? <p>{dateFormatter(new Date(cell.value))}</p> : <p>N/A</p>,
      },
      {
        Header:'Arrival Date',
        id: "Arrival Date",
        accessor: "arrival_date",
        Cell: ({ cell }:any) =>
          cell.value ? <p>{dateFormatter(new Date(cell.value))}</p> : <p>N/A</p>,
        filter: generalFilter,
      },
      
      {Header:'Intake Complete',
        id:'intake_complete',
        accessor: "arrival_date",
        Cell: ({ cell }: any) =>
          cell.value ? <p>{dateFormatter(new Date(cell.value))}</p> : <p>N/A</p>,
      },
      {
        Header:'NTP',
        id:'ntp_date',
        accessor:'ntp_date',
        Cell: ({ cell }: any) =>
          cell.value ? <p>{dateFormatter(new Date(cell.value))}</p> : <p>N/A</p>,
      },
      {
        Header:'Model Type',
        id:'module_type',
        accessor:'module_type'
      },
      {
        Header:'Test Sequence',
        id:'test_sequence_name',
        accessor:'test_sequence_name',
      },
      {
        Header:'Cell Type',
        id:'module_technology_name',
        accessor:'module_specification.module_technology_name'
      },
      {
        Header:'Module Dimensions',
        id:'module_dim',
        accessor:'module_specification.module_width',
        Cell: ({ cell, row }: any) => (
          <label>{cell.value}X{row.values.module_height}</label>
        ),
      },
      {
        Header:'Mpp',
        id:'Mpp',
        accessor:'module_specification.nameplate_pmax'
      },
      {
        Header:'Voc',
        id:'Voc',
        accessor:'module_specification.voc'
      },
      {
        Header:'Isc',
        id:'Isc',
        accessor:'module_specification.isc'
      },												
      {
        Header:'Vmp',
        id:'Vmp',
        accessor:'module_specification.vmp'
      },
      {
        Header:'Imp',
        id:'Imp',
        accessor:'module_specification.imp'
      },
      {
        Header:'Rated Voltage',
        id:'Rated Voltage',
        accessor:'module_specification.system_voltage'
      },
          
    ],
    []
  );

  "Arrival Date	Customer	Project	BOM	Manufacturer	Model Type	Serial Number	Test Sequence	Location	Intake Location	NTP	 Date	Notes	Cell Type	Module Dimensions	Mpp	Voc	Isc	Vmp	Imp	Rated Voltage	EOL Disposition	Project Closeout Date															"

  return (
    <>
      <div style={{ overflowX: 'auto', width: '100%' }}>

        <LSDBCoreTableWithoutDropDown
          columns={columns}
          data={data}
          hiddenColumns={["Id", "module_height"]}
        />

        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>Location History     <IconButton  onClick={getDownloads}><Save color="primary"/></IconButton></DialogTitle>
          <DialogContent>
            <HistoryTimeLine serial_number={selectedSerial} reloadHistory={reloadHistory}></HistoryTimeLine>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );




  
};


