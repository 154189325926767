import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Grid,
  useTheme,
  Typography,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { processErrorOnMutation } from "../../../../util/errorMessaging";
import { DateTimePicker } from "formik-material-ui-pickers";
import { useProjects } from "../../../intake/intakeQueries";
import { useFetchContext } from "../../../common/FetchContext";
import { useCreateCrateNew } from "../../../intake/intakeMutations";
import moment from "moment";
import { usePostCrateFile } from "../../../common/services/fileServices";
import { DropzoneArea } from "material-ui-dropzone";


export const CrateIntakeImageUpload = () => {

  const { id } = useParams() as {
    id: string;
  };
  const theme = useTheme();
  const { authAxios } = useFetchContext();
  const [files, setFiles] = React.useState([]);
  const { mutateAsync: mutatePostFile } = usePostCrateFile();


  const handleFileChange = (uploadedFiles: any) => {
    setFiles(uploadedFiles);
  };


  const catagories = [
    'Crates inside truck',
    'Each crate on the forklift',
    'All 4 sides of each crate when fully offloaded',
    'All visible damage',
    'Shipping labels'
  ];



  return (
    <div style={theme.container}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >

          <Grid item xs={12}>
            <Formik
              initialValues={{
                categories: "",
                notes:"",
              }}

              validationSchema={Yup.object({
                categories: Yup.string().required("Label requied"),
              })}

              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  console.log("Form values", JSON.stringify(values));
                  if (files.length > 0) {
                    for (let file of files) {
                      const postedFile = await mutatePostFile({
                        authAxios, file,
                        crate: id,
                        label_name: values.categories,
                        notes:values.notes
                      });

                    }
                  }

                  toast.success(
                    values.categories + " imaged uploaded successfully"
                  );

                  window.location.reload()
                  setSubmitting(false)

                } catch (error) {
                  processErrorOnMutation(error);
                }
              }}>
              {({ errors, touched, submitForm, resetForm }) => {
                return (
                  <Form>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Field
                          id="categories"
                          type="text"
                          select={true}
                          name="categories"
                          fullWidth
                          component={TextField}

                          style={{ marginBottom: 32 }}
                          label="Categories"
                          data-testid="categories"
                          helperText={touched.categories ? errors.categories : ""}
                          error={touched.categories && Boolean(errors.categories)}
                        >
                          {catagories.map((item) => (
                            <MenuItem
                              key={item}
                              value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Typography gutterBottom variant="subtitle1">
                        Upload Image(s) optional
                      </Typography>
                      <DropzoneArea
                        acceptedFiles={["image/*", ".pdf"]}
                        onChange={(files) => handleFileChange(files)}
                        filesLimit={
                          process.env.REACT_APP_FILE_LIMIT
                            ? parseInt(process.env.REACT_APP_FILE_LIMIT)
                            : 20
                        }
                        maxFileSize={12000000}
                        data-testid="fileUploadCrate"
                      />

                      <Grid item xs={8}>
                        <Field
                          id="notes"
                          type="text"
                          name="notes"
                          fullWidth
                          outlined
                          component={TextField}
                          style={{ marginBottom: 32 }}
                          label="Enter Notes"
                          data-testid="notes"
                        />

                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justify="space-around"
                          alignItems="center"
                          style={{ marginTop: 32 }}
                        >
                          <Grid item xs={2}>
                            <Button
                              data-testid="submitForgotPassword"
                              variant="contained"
                              color="primary"
                              onClick={submitForm}
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => resetForm()} >
                              Clear
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}
