import React from "react";
import { useFaildReports, useReports } from "../../projectManagementQueries";
import { LSDBCoreTable } from "../../../common/LSDBCoreTable";
import { Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { generalFilter } from "../../../../util/filter";
import { useHistory } from "react-router";
import { TravelerModal } from "../../../common/travelerModal";
import { useFetchContext } from "../../../common/FetchContext";
import { urls } from "../../../common/urls";
import moment from "moment";

const dateFormatter = (dateToFormat: Date) => {
  return `${dateToFormat.toDateString()} ${dateToFormat
    .getUTCHours()
    .toString()
    .padStart(2, "0")}:${dateToFormat
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}:${dateToFormat
    .getUTCSeconds()
    .toString()
    .padStart(2, "0")}`;
};

const Reports = () => {

  const {
    data: reports,
    error: reportsError,
    isLoading: isLoadingReports,
    isError: isErrorReports,
    refetch: refetchReports,
  } = useFaildReports();

  const { authAxios } = useFetchContext();
  const getDownloads = async (): Promise<any> => {
    const response = await authAxios.get(urls.projects.downloads,{
      responseType: 'blob'
    });

    const downloadUrl = URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${moment().format("MMM-DD-YYYY-HHMMSS")}`;
    document.body.appendChild(a);
    a.click();
  };




  const columns = React.useMemo(
    () => [
      
      {
        Header: "ID",
        accessor: "id",
        filter: generalFilter,
      },
      {
        Header: "Serial Number",
        accessor: "unit_serial_number",
        Cell: ({ cell }: any) => {
          return <TravelerModal serialNumber={cell.value} />;
        },
      },
      {
        Header: "Project Number",
        accessor: "project_number",
        filter: generalFilter,
      },
      {
        Header: "Project Name",
        accessor: "name",
        filter: generalFilter,
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        filter: generalFilter,
      },
      {
        Header: "Disposition",
        accessor: "disposition_name",
        filter: generalFilter,
      },

      {
        Header: "Work Order",
        accessor: "work_order_name",
        filter: generalFilter,
      },

     
      {
        Header: "Start Datetime",
        accessor: "start_datetime",
        Cell: ({ cell }: any) => {
          return cell.value !== null ? (
            <p>{dateFormatter(new Date(cell.value))}</p>
          ) : (
            <p>N/A</p>
          );
        },
        filter: generalFilter,
      },
      {
        Header: "End Date",
        accessor: "end_datetime",
        Cell: ({ cell }: any) => {
          const promiseDatetime = new Date(cell.value);

          return cell.value !== null ? (
            <p>{dateFormatter(promiseDatetime)}</p>
          ) : (
            <p>N/A</p>
          );
        },
        filter: generalFilter,
      },

    ],
    []
  );

  if (isLoadingReports) {
    return <LinearProgress />;
  }

  if (isErrorReports) {
    return <ErrorMessage error={reportsError} />;
  }



  return (
    <>
    <Grid
       container
       direction="row"
       alignItems="center"
       spacing={0}>
        <Grid item xs={11}>
        <Typography variant="h3">Last 18 Month Failure Reports</Typography>
        </Grid>
        <Grid item xs={1}>
        <Button
                variant="contained"
                color="primary"
                onClick={getDownloads}
              >
                Download
              </Button>
        </Grid>
    
    </Grid>
      
      {reports && (
        <LSDBCoreTable
          columns={columns}
          data={reports}
          hiddenColumns={["id"]}
          getCellProps={(cellInfo: any) => {
            if (cellInfo.column.Header === "ETA Datetime") {
              const originalInfo = cellInfo.row.original;
              const etaDatetime = new Date(originalInfo.eta_datetime);
              const today = new Date();
              const dayDiff = Math.floor(
                (Date.UTC(
                  etaDatetime.getFullYear(),
                  etaDatetime.getMonth(),
                  etaDatetime.getDate()
                ) -
                  Date.UTC(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate()
                  )) /
                  (1000 * 60 * 60 * 24)
              );

              return {
                style: {
                  backgroundColor:
                    dayDiff <= 0 ? "red" : dayDiff === 1 ? "yellow" : "green",
                  fontWeight: "bold",
                  color: dayDiff === 1 ? "black " : "white",
                },
              };
            }
            if (cellInfo.column.Header === "Ready to process?") {
              if (!cellInfo.value) return {};
              return { style: { backgroundColor: "green" } };
            }

            return {};
          }}
        />
      )}
    </>
  );
};

export default Reports;
