import React from "react";
import { useRouteMatch, Switch, Redirect } from "react-router-dom";
import { DefaultLayout } from "../DefaultLayout";
import { PrivateRoute } from "../../auth/PrivateRoute";
import { OpsManagementList } from "./OpsManagement";
import { DataLandingPage } from "../../dataEgress/dataLandingPage";
import { ProductivityLog } from "../../projectManagement/components/productivityLog/prodLogPage";
import { StressReadyStatus } from "../../projectManagement/components/stressStatus/StressReadyStatus";
import { StressEntryPage } from "../../projectManagement/components/stressEntry/StressEntryPage";
import { OpsQueuesMainPage } from "../../projectManagement/components/opsQueues/OpsQueuesMainPage";
import { StressExitPage } from "../../projectManagement/components/stressEntry/StressExitPage";
import { StressEntryDetailsContextProvider as StressEntry } from "../../projectManagement/components/stressEntry/StressEntryContext";
import { OpsAgendaPage } from "../../projectManagement/components/opsAgenda/OpsAgendaPage";
import { TagCreation } from "../../projectManagement/components/tagCreation/TagCreation";
import TemplatesPage from "../../projectManagement/components/templates/TemplatesPage";
import TemplateDetails from "../../projectManagement/components/templates/TemplateDetails";
import NewTemplate from "../../projectManagement/components/templates/NewTemplate";
import { NewUnitTypePage } from "../../projectManagement/components/unitTypes/NewUnitTypePage";
import { NewModuleIntakeFirst } from "../../projectManagement/components/moduleIntake/newModuleIntake";
import { ModuleIntakePanel } from "../../projectManagement/components/moduleIntake/moduleIntakePanel";
import { ModuleIntakeUpload } from "../../projectManagement/components/moduleIntake/moduleIntakeUpload";
import { StressPausePage } from "../../projectManagement/components/stressEntry/StressPausePage";
import { StressResumePage } from "../../projectManagement/components/stressEntry/StressResumePage";
import { CrateIntakePage } from "../../intake/components/crateIntake";
import { CrateIntake } from "../../projectManagement/components/crateIntake/CrateIntake";
import { CrateIntakeImageUpload } from "../../projectManagement/components/crateIntake/CrateIntakeImageUpload";
import { CrateIntakeList } from "../../projectManagement/components/crateIntake/crateIntakeList";
import { ModuleIntakeList } from "../../projectManagement/components/moduleIntake/moduleIntakeList";
import { useModuleIntakeInfo } from "../../intake/intakeQueries";
import { ModuleInfo } from "../../projectManagement/components/moduleIntake/moduleInfo";
import { CrateInfo } from "../../projectManagement/components/crateIntake/crateInfo";
import { ModuleInventoryPage } from "../../projectManagement/components/module_inventory/ModuleInventoryPage";
import { ModuleInventoryInfo } from "../../projectManagement/components/module_inventory/ModuleInventoryInfo";

export const OpsManagement = () => {
  const { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <StressEntry>
        <Switch>
          <PrivateRoute exact path={`${path}`} component={OpsManagementList} />
          <PrivateRoute
            path={`${path}/data_verification`}
            component={DataLandingPage}
          />
          <PrivateRoute
            path={`${path}/stress_entry_queue`}
            component={StressReadyStatus}
          />
          <PrivateRoute
            exact
            path={`${path}/work_log`}
            component={ProductivityLog}
          />
          <PrivateRoute
            exact
            path={`${path}/stress_entry`}
            component={StressEntryPage}
          />
          <PrivateRoute
            exact
            path={`${path}/stress_pause`}
            component={StressPausePage}
          />
          <PrivateRoute
            exact
            path={`${path}/stress_resume`}
            component={StressResumePage}
          />
          <PrivateRoute
            exact
            path={`${path}/stress_exit`}
            component={StressExitPage}
          />
          <PrivateRoute
            exact
            path={`${path}/ops_queues`}
            component={OpsQueuesMainPage}
          />
          <PrivateRoute
            exact
            path={`${path}/templates`}
            component={TemplatesPage}
          />
          <PrivateRoute
            exact
            path={`${path}/templates/new_template`}
            component={NewTemplate}
          />
          <PrivateRoute
            exact
            path={`${path}/templates/:templateId`}
            component={TemplateDetails}
          />
          <PrivateRoute
            exact
            path={`${path}/ops_agenda/:note_id?`}
            component={OpsAgendaPage}
          />
          <PrivateRoute
            exact
            path={`${path}/ops_agenda/new_label`}
            component={TagCreation}
          />
          <PrivateRoute
            exact
            path={`${path}/module_intake`}
            component={ModuleIntakeList}
          />
          <PrivateRoute
            exact
            path={`${path}/module_intake/intake`}
            component={NewModuleIntakeFirst} />
          <PrivateRoute
            exact
            path={`${path}/module_intake/intake/intake :id`}
            component={ModuleIntakePanel} />
            <PrivateRoute
            exact
            path={`${path}/module_intake/module :id`}
            component={ModuleInfo} />
           <PrivateRoute
            exact
            path={`${path}/module_intake/intake/intake :id`}
            component={ModuleIntakePanel} />
            <PrivateRoute
            exact
            path={`${path}/module_inventory`}
            component={ModuleInventoryPage} />  
          <PrivateRoute
            exact
            path={`${path}/module_intake/intake/upload:id`}
            component={ModuleIntakeUpload} />

           <PrivateRoute
            exact
            path={`${path}/crates`}
            component={CrateIntakeList} />  

          <PrivateRoute
            exact
            path={`${path}/crates/crate_intake`}
            component={CrateIntake} />

          <PrivateRoute
            exact
            path={`${path}/crates/crate_intake/:id`}
            component={CrateIntakeImageUpload} />
            <PrivateRoute
            exact
            path={`${path}/crates/Crate :id`}
            component={CrateInfo} />
          <PrivateRoute
            exact
            path={`${path}/crates/crate_intake/:id`}
            component={CrateIntakeImageUpload} />
            <PrivateRoute
            exact
            path={`${path}/module_inventory/:serial_number`}
            component={ModuleInventoryInfo} />
          <Redirect to="/not_found" />
        </Switch>
      </StressEntry>
    </DefaultLayout>
  );
};
