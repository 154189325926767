import React from "react";
import { useLocations, useModuleIntake, useProjectData } from "../../../intake/intakeQueries";
import { Backdrop, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CrateIntakeTable } from "./crateIntakeTable";
import { useCrateList } from "../../../intake/intakeMutations";

export const CrateIntakeList = () => {
    const [locationsData, setLocationsData] = React.useState(false);
    const history = useHistory();

    const {
        error: errorData,
        data: crateList,
        isLoading: isloding,
        isError: isErrorData,
    } = useCrateList();


    if (isloding) {
        return (
            <Backdrop open={isloding}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

  

    const newModuleIntake = () => {
        history.push(`/operations_management/crates/crate_intake`);
    };
  

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={1} >
                <Grid item xs={8}>
                    <Typography variant="h3"> Crates</Typography>
                </Grid>
    
                <Grid item xs={1}>
                    <Button
                        style={{ textAlign: "right" }}
                        variant="contained"
                        color="primary"
                        onClick={newModuleIntake}>
                        ADD NEW
                    </Button>
                </Grid>
            </Grid>
        <CrateIntakeTable data={crateList}></CrateIntakeTable>
        </>);
}