import React from "react";
import { useLocations, useModuleIntake, useProjectData } from "../../../intake/intakeQueries";
import { Backdrop, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ModuleIntakeTable } from "./moduleIntakeTable";

export const ModuleIntakeList = () => {
    const [locationsData, setLocationsData] = React.useState(false);
    const history = useHistory();

    const {
        error: errorData,
        data: intakeList,
        isLoading: isloding,
        isError: isErrorData,
    } = useModuleIntake();
    
    if (isloding) {
        return (
            <Backdrop open={isloding}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

  

    const newModuleIntake = () => {
        history.push(`/operations_management/module_intake/intake`);
    };

  

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={1} >
                <Grid item xs={8}>
                    <Typography variant="h3"> Modules</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="location_select">Locations</InputLabel>
                        <Select
                            labelId="location_select"
                            id="location_select"
                            value={1}
                            label="Loactions">
                                <MenuItem value={1}>Napa</MenuItem>
                            {/* {locations?.map((location: any) => (
                                <MenuItem
                                    key={location.id}
                                    value={location.name}>
                                    {location.name}
                                </MenuItem>
                            ))} */}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={1}>
                    <Button
                        style={{ textAlign: "right" }}
                        variant="contained"
                        color="primary"
                        onClick={newModuleIntake}>
                        ADD NEW
                    </Button>
                </Grid>
            </Grid>
        <ModuleIntakeTable data={intakeList}></ModuleIntakeTable>
        </>);
}