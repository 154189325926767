import React from "react";
import { Box, Container, Paper, Typography } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { DefaultLayout } from "../DefaultLayout";

export const FailedProjectManagement = () => {
  return (
    <Container>
      <Typography variant="h4">Custom Reports</Typography>
      <Box padding={2}>
      <Paper>
        <Box padding={2}>
        <MuiLink
          component={Link}
          to={"/project_management/custom_reports/reports"}>
          
          <Typography variant="h6">Last 18 Months Failure Reports</Typography>
        </MuiLink>

        <MuiLink
          component={Link}
          to={"/project_management/custom_reports/flash_reports"}>
          
          <Typography variant="h6">Flash Reports</Typography>
        </MuiLink>
       </Box>
      </Paper>
      </Box>
    </Container>
  );
};
